const importScript = (resourceUrl, init) => {
  let isScriptLoaded = document.querySelector('script[src="' + resourceUrl + '"]') ? true : false
  if(isScriptLoaded){
    init();     
  }else{
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.id = resourceUrl;
    script.async = true;
    script.onload = function(){ 
      init();      
    }
    script.onerror = function(){
      //reject();
    }
    
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    }
  }
}
export default importScript;