import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import {Link} from "react-router-dom";
import useStyle from '../../hooks/useStyle';
import CommonService from '../../services/CommonService';
import Notification from "./Notification"
import LiveNotification from "./LiveNotification"
import NotificationRoute from  "./NotificationRoute"
import SideMenuBar from "../../components/Common/SideMenuBar";

let requestParams = {}
let selectedNotification = null;

const NotificationList = (props) => {
  useStyle('dashboard');
  
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
    }

    if(props.instantNotification){
      setNotifications([props.instantNotification]);
    }else{
      getNotificationsList();
	} 
	//document.addEventListener("keydown", handleEscape)
    //document.addEventListener("click", handleClick, true)
    return () => {
      //document.removeEventListener("keydown", handleEscape)
      //document.removeEventListener("click", handleClick, true)
    }
  }, [props.searchString]);

  /*const handleEscape = (e) => {
    if (e.keyCode === 27)  props.toggleNotification(e)
  }

  const handleClick = (e) => {
	 props.toggleNotification(e)

  }*/

  let getNotificationsList = () => { 
    CommonService.getNotifications(requestParams).then((res)=>{
     if(res.status == 200){
       setNotifications(res.data.notifications)
      }
    })
  }
  
  const onDelete = (e, notification) => {
    selectedNotification = notification;
    CommonService.deleteNotification({id:selectedNotification.id}).then((res) => {
      if(res.status==204){
        setNotifications(notifications.filter(r => r.id !== selectedNotification.id));
      }
    })   
  }

  const setNotificationRead = (n) => {
    let req = {'status':'read'}
    CommonService.updateNotification(n.id, req).then((res)=>{
      n.status = 'read';
    })
  }

  const routeNotification = (n, e) =>{
    setNotificationRead(n)
    NotificationRoute.routeNotification(n, props)
    props.toggleNotification(e)
  }

  const NotificationView = () => notifications.map((n, i) => (
    <Notification key={i} notification={n} onDelete={onDelete} routeNotification={routeNotification}/>           
  ))

  const LiveNotificationView = () => notifications.map((n, i) => (
    <LiveNotification key={i} notification={n} onDelete={onDelete} routeNotification={routeNotification}/>           
  ))

  const Notifications = () =>(
    <div className={`col-xs-12 ${props.showAsPopup && 'popup-notification'}`} >
      <div className="card notification-ui_dd" >
        <div style={{margin:"0px 15px 10px 15px"}}>
          <div className="notification-ui_dd-header" >
    		<h3><i className="fas fa-comments m-r-5 font-24 color-highlight"/> Notifications          {props.showAsPopup && <span onClick={e=>props.toggleNotification(e)} className="clearfix fa fa-times notification-close"></span>}
</h3>
          </div>
		  <div className={`m-t-10 ${props.showAsPopup && 'notification-ui_dd-content'}`}>
          {props.instantNotification ? <LiveNotificationView/> : <NotificationView/>}
		  </div>
		  {props.showAsPopup &&<div className="notification-ui_dd-footer" >
		  <a className="notification-list notification-success notification-block" href="/notifications">
            View All
		  </a>
          </div>}
		</div>
      </div>
    </div>
  )
  
  if(props.showAsPopup){
    return(ReactDOM.createPortal(<Notifications/>, document.body))
  }

  return (
    <React.Fragment>
      <Notifications/>
      <SideMenuBar helpId="2"/>
    </React.Fragment>
  )

};

export default NotificationList;