import React, {useState, useEffect, useRef} from 'react';
import useStyle from '../../hooks/useStyle'
import CommonService from '../../services/CommonService'
/*https://codepen.io/Shokeen/pen/pgryyN*/

const RichText = (props) => {
  useStyle('rich_text')

  const inputRichText = useRef(null);

  let colorPalette = ['000000', 'FF9966', '6699FF', '99FF66', 'CC0000', '00CC00', '0000CC', '333333', '0066FF', 'FFFFFF'];

  useEffect(() => {
    inputRichText.current.innerHTML = props.content || null   
    document.execCommand("defaultParagraphSeparator", false, "p")
    /*inputRichText.current.addEventListener("selectstart", (s) => {
      console.log(s)
      console.log(document.selection)
      document.addEventListener("selectionchange", (e)=>{
        console.log(e)
        console.log(document.selection)
      })
    })*/
  }, [])
  
  const colorPaletteEle = (command) => colorPalette.map((c, k) =>  (
    <a key={k} href="#" onClick={e=>onCommandSelect(e, command, c)} data-value={c} style={{'backgroundColor': '#' + c}} className="palette-item"></a>
  ))
  
  const onCommandSelect = (e, command, value) =>{
    e.preventDefault();
    if (command == 'h1' || command == 'h2' || command == 'p') {
      document.execCommand('formatBlock', false, command);
    }
    if (command == 'forecolor' || command == 'backcolor') {
      document.execCommand(command, false, value);
    }

    if (command == 'createlink') {
      let url = prompt('Enter the link here: ', 'http:\/\/');
      if(url)document.execCommand(command, false, url);
    } 

    if(command == 'insertImage'){
      document.getElementById('rich_text_file_upload').click()
    }
    /*else {
      document.execCommand(command, false, null);
    }*/
  }

  const onFileUpload = (e) =>{
    let files = e.target.files
    let ele = e.target
    e.preventDefault();

    try {
      var FileSize = files[0].size / 1024 / 1024; // in MB
      if (FileSize > 2) {
        ele.value = null
        return
      }else{
        
      }

     if(files.length>0){
        let imageData = {'fileable_type':props.dataType, 'fileable_id':props.dataId, 'is_rich_text':true}
        CommonService.uploadFileToUrl(files[0], null, imageData).then((data)=> {
          let fileImage = CommonService.getIconByType(data.file_upload.url)
          ele.value = null
          let sel = document.selection;
          if (sel) {
            let textRange = sel.createRange();
            document.execCommand('insertImage', false, fileImage);
            textRange.collapse(false);
            textRange.select();
          } else {
            document.execCommand('insertImage', false, fileImage);
          }
        }, (err)=>{

        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <div className="toolbar">
        <a href="#" onClick={e=>onCommandSelect(e, "undo")}>
          <i className="fas fa-undo" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "redo")}>
          <i className="fas fa-redo" />
        </a>
        <div className="fore-wrapper">
          <i className="fa fa-font" style={{ color: "#C96" }} />
          <div className="fore-palette">
            {colorPaletteEle('forecolor')}
          </div>
        </div>
        <div className="back-wrapper">
          <i className="fa fa-font" style={{ background: "#C96" }} />
          <div className="back-palette">
            {colorPaletteEle('backcolor')}
          </div>
        </div>
        <a href="#" onClick={e=>onCommandSelect(e, "bold")}>
          <i className="fa fa-bold" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "italic")}>
          <i className="fa fa-italic" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "underline")}>
          <i className="fa fa-underline" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "strikeThrough")}>
          <i className="fa fa-strikethrough" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "justifyLeft")}>
          <i className="fa fa-align-left" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "justifyCenter")}>
          <i className="fa fa-align-center" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "justifyRight")}>
          <i className="fa fa-align-right" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "justifyFull")}>
          <i className="fa fa-align-justify" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "indent")}>
          <i className="fa fa-indent" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "outdent")}>
          <i className="fa fa-outdent" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "insertUnorderedList")}>
          <i className="fa fa-list-ul" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "insertOrderedList")}>
          <i className="fa fa-list-ol" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "h1")}>
          H1
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "h2")}>
          H2
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "createlink")}>
          <i className="fa fa-link" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "unlink")}>
          <i className="fa fa-unlink" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "insertImage")}>
          <i className="fa fa-image" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "p")}>
          P
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "subscript")}>
          <i className="fa fa-subscript" />
        </a>
        <a href="#" onClick={e=>onCommandSelect(e, "superscript")}>
          <i className="fa fa-superscript" />
        </a>
      </div>
      <input id="rich_text_file_upload" onChange={e => onFileUpload(e)} type="file" className="hidden"/>
      <div ref={inputRichText} className="editor" onInput={e=>props.onContentChange(e)}  contentEditable suppressContentEditableWarning="true"> 
      </div>
    </div>
  )
}

export default RichText;