import React from "react";
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import moment from '../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../hooks/useStyle';
import PushNotification from "../../components/PushNotification/PushNotification"
import { Link } from "react-router-dom";

export const Login = (props) => {
  useStyle('signin');  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  const { state: authState, dispatch } = React.useContext(AuthContext);
  
  /*const getEmail = () => {
    if (localStorage.checkbox && localStorage.email !== "") {
     return localStorage.email
    }else{
      return ""
    }
  };

  const getPassword = () => {
    if (localStorage.checkbox && localStorage.password !== "") {
     return localStorage.password
    }else{
      return ""
    }
  };

  const getChecked = () => {
    if (localStorage.checkbox && localStorage.checkbox !== "") {
     return localStorage.checkbox
    }else{
      return false
    }
  };*/

  const userInitialState = {
    email: '',
    password: '',
    isChecked: '',
    isSubmitting: false,
    errorMessage: null,
  }

  const [user, setUser] = React.useState(userInitialState);

  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleRememberMe = (event) => {
    console.log('rememberme',event,user)
    setUser({
      ...user,
      isChecked: event.target.checked
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setUser({
      ...user,
      isSubmitting: true,
      errorMessage: null,
    })
    /*if (user.isChecked) {
      localStorage.email = user.email
      localStorage.password = user.password
      localStorage.checkbox = true
    }*/
    let req = { customer: { 
        email: user.email, 
        password: user.password,
        
      } 
    }
    AuthenticationService.signin(req).then((res) => {
      if (res.status == 201) {
        dispatch({
          type: "LOGIN",
          payload: res.data,
        })
        if(authState.userAgent != 'Safari')PushNotification();
        routeUser(res.data.user)
      } else {
        setUser({
          ...user,
          isSubmitting: false,
          errorMessage: res.data.error || res.statusText,
        })
      }
    }).catch((error) => {
      console.log('error',error)
      setUser({ ...user, errorMessage: 'Network Error' });
    })
  }

  const routeUser = (user) => {
    console.log('user login health',user)
    //props.history.push('/getstarted', authState);
    /*if(user.domain_info.data == null){
      props.history.push("/profile?show_form_wizard=1");
    }else{*/
      props.history.push("/dashboard");
    //}
  }
  
  React.useEffect(() => {
    if(authState.isAuthenticated){
      routeUser(authState.user);
    }
    invitationLinkVisited();
  }, [authState.isAuthenticated]);

  const invitationLinkVisited = () => {
    if(queryParam.share_type == 'ribbon'){
      if(queryParam.invite_id && queryParam.invite_id.length>0){
        AuthenticationService.inviteRibbonLinkVisited(queryParam.invite_id).then(function(response) {
          if (response.status == 204) {

          }
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20 ht-150"  />
        <div
          className="page-title-bg dark-mode-tint ht-150"
          
        />
        <div
          className="page-title-bg opacity-90 bg-highlight ht-150"
          
        />
        <div className="page-title-small color-white bottom-30">
          <h1>
            Sign In
          </h1>
        </div>
        <form className="signform">
        <div className="content-boxed content-boxed-full left-20 right-20 shadow-large">
          <div className="content top-10 bottom-20">
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-user font-11" />
              <span>Email</span>
              <em>(required)</em>
              <input
                type="email"
                placeholder="email"
                name="email"
                onChange={handleInputChange}
                value={user.email}
              />
            </div>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input
                type="password"
                placeholder="password"
                name="password"
                onChange={handleInputChange}
                value={user.password}
              />
            </div>
            <div className="input-style has-icon">
           <div className="d-flex">
             <input type="checkbox" checked={user.isChecked} name="lsRememberMe" onChange={handleRememberMe} /><label style={{marginLeft:'5px'}}>Remember me</label>
           </div> 
                                
            </div>
            {user.errorMessage && (
              <span className="error">{user.errorMessage}</span>
            )}

            <div className="clear" />
            <button
              onClick={handleFormSubmit}
              className="button button-full button-m shadow-large button-round-small bg-green1-dark top-30 bottom-0"
            >
              Sign In
            </button>
            <div className="divider top-30" />
            <div className="display-inline" style={{width:"100%",textAlign:"center"}}>
            <div className="three-half">
              <Link to="/signup" className="font-11 color-theme opacity-50">
                Create Account
              </Link>
            </div>
            <div className="three-half ">
              
            </div>
            <div className="three-half">
              <Link to="/forget_password"
                className="text-right font-11 color-theme opacity-50">
                Forgot Password
              </Link>
            </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        </form>
        <div className="signinbg">
        <div className="signincon">
          <img src={'./../../../images/cueTree_logo.png'} style={{display: 'block',marginLeft: 'auto',marginRight: 'auto',width: '50%'}} />
          <div className="font-500 font-16">COMMERCE</div>
        </div>
       
        <div className="signcopy hidden-xs">
        <div className="content bottom-10 social-sharing">
          <a  className="shareToTwitter icon icon-xs icon-round bg-twitter regularbold"><i className="fab fa-twitter"></i></a>
          <a  className="shareToTwitter icon icon-xs icon-round bg-instagram regularbold"><i className="fab fa-instagram"></i></a>
          <a  className="shareToFacebook icon icon-xs icon-round bg-facebook regularbold"><i className="fab fa-facebook-f"></i></a>
          <a  className="shareToWhatsApp icon icon-xs icon-round bg-linkedin regularbold"><i className="fab fa-linkedin"></i></a>
          <a  className="shareToWhatsApp icon icon-xs icon-round bg-whatsapp regularbold"><i className="fab fa-whatsapp"></i></a>
        </div>
        <i className="far fa-copyright"></i> 2021 IPIPAL INC. All Rights Reserved.</div>
        </div>
        <div className="footer" data-footer-load="menu-footer.html" />
      </div>
    </React.Fragment>
  )
};

export default Login;
