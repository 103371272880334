import env from '../env';
import axios from 'axios';
const url = `${env.health_backend}health/checklists`

class ChecklistService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  add(checklist){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, checklist).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(checklist){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${checklist.id}`, checklist).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  status(checklist){
    return new Promise((resolve, reject) => {
      console.log('status toggle',checklist)
      axios.put(`${url}/${checklist.id}/status`, checklist).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new ChecklistService();