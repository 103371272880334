export default {
  commerce_backend:'http://commerce-api.beta.cuetree.com/',
  health_backend: 'https://health.api.cuetree.com/',
  ace_backend: 'https://ace.api.cuetree.com/',
  backend: 'https://beta.cuetree.com/',
  socket:'https://www.cuetree.com/faye',
  profile_image_url:'https://selftree-images.s3.amazonaws.com/uploads/user/profile_image/',
  file_url:'https://life.admin.cuetree.com/files?url=',
  admin_emails:['info@cuetree.com', 'author@ipipal.com', 'skasi@ipipal.com']
};

/*export default {
  commerce_backend: 'http://127.0.0.1:9000/',
  ace_backend: 'https://ace.api.cuetree.com/',
  backend: 'https://beta.cuetree.com/',
  socket:'https://www.cuetree.com/faye',
  profile_image_url:'https://selftree-images.s3.amazonaws.com/uploads/user/profile_image/',
  file_url:'https://life.admin.cuetree.com/files?url=',
  admin_emails:['info@cuetree.com', 'author@ipipal.com', 'skasi@ipipal.com']
};*/
