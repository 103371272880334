import env from '../env';
import axios from 'axios';
const url = `${env.backend}notes`

class NotesService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  add(note){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, note).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(note){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${note.id}`, note).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new NotesService();