import React from "react";
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import { Link } from "react-router-dom";

let serverErrorMessage = {}

export const Signup = (props) => {
  useStyle('signup');
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  const { state: authState, dispatch } = React.useContext(AuthContext);

  const initialState = {
    email: "",
    password: "",
    cpassword: "",
    isSubmitting: false,
    isChecked:false,
    errorMessage: {},
    errorSign: {
      username: false,
      email: false,
      password: false,
      cpassword: false,
    },
  };

  const [data, setData] = React.useState(initialState);

  const handleTerms = (event) => {
    console.log('rememberme',event,data)
    setData({
      ...data,
      isChecked: event.target.checked
    });
  }

  const handleInputChange = (event) => {
    console.log(
      "pre event clicked",
      event.target.value,
      event.target.name,
      data
    );
    if (event.target.value === "") {
      console.log("empty");
      let errorMessage = data.errorMessage;
      errorMessage[event.target.name] = [event.target.name] + " required";
      let errorSign = data.errorSign;
      errorSign[event.target.name] = false;
      setData({
        ...data,
        errorMessage: errorMessage,
        errorSign: errorSign,
      });
    } else {
      console.log("not empty");
      if (data.errorMessage[event.target.name]) {
        delete data.errorMessage[event.target.name];
      }
      let errorSign = data.errorSign;
      errorSign[event.target.name] = true;
      setData({
        ...data,
        [event.target.name]: event.target.value,
        errorMessage: data.errorMessage,
        errorSign: errorSign,
      });
    }

    console.log(
      "post event clicked",
      event.target.value,
      event.target.name,
      data
    );
  };

  const checkError = () => {
    for (const property in data.errorSign) {
      if (data.errorSign[property] === false) return false;
    }
    if(data.isChecked === false){
      return false
    }
    return true;
  };

  React.useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/products')
    }
    invitationLinkVisited();
  }, [authState.isAuthenticated]);

  const handleFormSubmitSignup = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
    });
    let req = {
      customer: {
        email: data.email,
        password: data.password,
        password_confirmation: data.cpassword,
      },
    };
    AuthenticationService.signup(req).then((res) => {
      if (res.status == 201) {
          dispatch({
            type: "Signup",
            payload: res.data.data,
          });
          props.history.push('/signin');
      } else {
          setData({
            ...data,
            isSubmitting: false,
            serverErrorMessage: res.data.errors || res.statusText,
          });
        }
      }).catch((error) => {
        setData({ ...data, serverErrorMessage: {'error':'Network Error'} });
      })
  }
  
  const invitationLinkVisited = () => {
    if(queryParam.share_type == 'ribbon'){
      if(queryParam.invite_id && queryParam.invite_id.length>0){
        AuthenticationService.inviteRibbonLinkVisited(queryParam.invite_id).then(function(response) {
          if (response.status == 204) {

          }
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20 ht-150" >
          {/* image */}
        </div>
        <div
          className="page-title-bg dark-mode-tint ht-150"
          
        >
          {/* contrast for dark mode */}
        </div>
        <div
          className="page-title-bg opacity-90 bg-highlight ht-150"
          
        >
          {/* background color */}
        </div>
        <div className="page-title-small color-white bottom-30">
          <h1>
            Sign Up
          </h1>
        </div>
        <form className="signform">
        <div className="content-boxed content-boxed-full left-20 right-20 shadow-large">
          <div className="content bottom-0 top-10">
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-user font-11" />
              <span>Username</span>
              <em>(required)</em>
              <input
                type="name"
                placeholder="Username"
                name="username"
                onChange={handleInputChange}
                required
              />
            </div>
            {data.errorMessage["username"] && (
              <span className="error">
                {data.errorMessage["username"]}
              </span>
            )}
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-at" />
              <span>Email</span>
              <em>(required)</em>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleInputChange}
                required
              />
            </div>
            {data.errorMessage["email"] && (
              <span className="error">{data.errorMessage["email"]}</span>
            )}
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input
                type="password"
                placeholder="Choose a Password"
                name="password"
                onChange={handleInputChange}
                required
              />
            </div>
            {data.errorMessage["password"] && (
              <span className="error">
                {data.errorMessage["password"]}
              </span>
            )}
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input
                type="password"
                placeholder="Confirm your Password"
                name="cpassword"
                onChange={handleInputChange}
                required
              />
            </div>
            {data.errorMessage["cpassword"] && (
              <span className="error">
                {data.errorMessage["cpassword"]}
              </span>
            )}
            <div className="input-style has-icon">
           <div className="d-flex">
             <input type="checkbox"
                name="terms"
                onChange={handleTerms}
                required /><label style={{marginLeft:'5px'}}>I Accept the Terms of Use and Privacy Policy.</label></div>
            </div>
            {data.errorMessage["tc"] && (
              <span className="error">
                {data.errorMessage["tc"]}
              </span>
            )}
            <div className="clear" />

            {data.errorMessage && (
              <span className="error">{data.errorMessage}</span>
            )}
            <button onClick={handleFormSubmitSignup} disabled={checkError() ? false : true}
              className="button button-full button-m shadow-large button-round-small bg-green1-dark top-30 bottom-30">
              {data.isSubmitting ? "Loading..." : "CREATE ACCOUNT"}
            </button>
            <div className="divider bottom-20" />
            
              <div className="bottom-20">
                <Link to="/signin" className="back-button center-text font-11 color-highlight">
                  Already Registered? Sign In Here.
                </Link>
              </div>
              <div className="bottom-20" style={{textAlign:"center"}}>
            </div>
            </div>
        </div>
        </form>
        {/* Footer */}
        <div className="signinbg">
        <div className="signincon">
          <img src={'./../../../images/cueTree_logo.png'} style={{display: 'block',marginLeft: 'auto',marginRight: 'auto',width: '50%'}} />
          <div className="font-500 font-16">COMMERCE</div>
        </div>
        <div className="signcopy hidden-xs">
        <i className="far fa-copyright"></i> 2021 IPIPAL INC. All Rights Reserved.</div>
        </div>
        <div className="footer" data-footer-load="menu-footer.html" />
      </div>
    </React.Fragment>
  )
};

export default Signup;
