import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import querystringify from "querystringify"
import PreLoadSpinner from "../../PreLoadSpinner";
import DynamicForm from '../../FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import ProductCommerceService from "../ProductCommerceService";
import CommonService from "../../../services/CommonService";
import FormWizard from '../../FormBuilder/FormWizard';
import env from '../../../env';

let formMode = null; 
let eventProfile = {}; 
let data = {}; 
let formFn = {};  

let ProductId = null
let SupplierId = null


function ProductOrderForm(props) {
  console.log('health form ',props)
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  ProductId= params.product_id
  SupplierId= params.id

  console.log('params form product',params)
  let queryParam = querystringify.parse(props.location.search);
  
  
  let currentUser = authState.user;
  
  useEffect(() => { 
    formMode = 'create-form-submissions';
    data = {}; 
    eventProfile = {}; 
   
    formFn = {'form_type':'product_order_form'};
    setFormRendered(true);
  }, []);

  const create = (product) => {
    product.created_by = authState.user.id;
    product.product_id = ProductId
    product.supplier_id = SupplierId
    ProductCommerceService.createOrders(product).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect(true);
      }
    }).catch(function(res){
       
    })
  }

  const update = (product) => {
    ProductCommerceService.update(product).then((res) => {
      if(res.status==204){
        eventProfile = product;
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = (show_success_message) => {
    props.history.push(`/products`)  
  }

  const back = () => {
    props.history.goBack();
  }


  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details m-t-10" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.ProductOrderForm} form={eventProfile} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={back} 
          setPost={setPost} />
        </div>
      </div>
    </div>
    );  
  }else{
    return null;
  }
  
}

export default ProductOrderForm;