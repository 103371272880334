import React, { useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import CommonService from "../../../services/CommonService";
import ProductCommerceService from "../ProductCommerceService";
import useStyle from "../../../hooks/useStyle";

let requestParams = {};
let data_source = {};

export const Product = (props) => {
  console.log("props product", props);
  let {
    event,
    requestSample,
    requestInfo,
    addToCart,
    users,
    currentUser,
    dataSources,
  } = props;
  let productData = event.data;
  const [productList, setProductList] = useState([]);

  //let sportType = Object.values(dataSources.type_of_sports.options).filter(sports => sports.value == data.sport)

  React.useEffect(() => {
    requestParams = props.event.id;

    getProduct();
  }, []);
  let getProduct = () => {
    ProductCommerceService.getById(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        console.log("productgetbyId", res);
        setProductList(res.data.product);
      }
    });
  };

  const Header = () => (
    <div className="white card-header p-5 bg-highlight">
      <div className="font-16 m-t-7">
        {productList.data.product_name}
        <i className="fas fa-expand pull-right white expand"></i>
      </div>
    </div>
  );

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left">
        <span className="m-l-15 lgrey">
          {productList.form_id} - {productList.record_id}
        </span>
        <span className="m-l-25"
          onClick={(e) => props.productGallery(e, productList)}>
        <i className="fas fa-camera font-18 text-muted" aria-hidden="true"></i>
        </span>
      </div>
      <div className="pull-right m-r-10">
        <props.menu pr={productList} />
      </div>
    </div>
  );

  if (productList.length != 0) {
    let data = productList.data;
    let ds = productList.data_source;
    let marketing_labels = productList.marketing_labels || [];

    console.log('marketinglabel',marketing_labels)
    const LabelField = ({styleName}) => marketing_labels && marketing_labels.map((d, i) => (
      <span className={styleName +' m-l-5'} key={i}>{dataSources.marketing_label.options[d].label}</span>
    ))

    const SpanField = ({ name, label, styleName }) => (
      <span className={styleName}>{ds[name] && ds[name][label]}</span>
    );

    const SpanList = ({ name, label, styleName }) =>
      ds[name] &&
      ds[name].map((d, i) => (
        <span className={styleName + " m-l-5"} key={i}>
          {d.data[label]}
        </span>
      ));

    return (
      <Fragment>
        <div className="card-container bg-white shadow-small">
          <Header />
          <div class="ht-200 scroll-auto">
            <table className="table card-table">
              <tbody>
              <tr>
                <td>
                <label className="pill white bg-highlight underline">Status</label>
                <LabelField styleName="pill" />
                </td>
              </tr>
                <tr>
                  <td>
                    <SpanList name="plant_part" label="label" />
                    <SpanField
                      name="grade"
                      label="label"
                      styleName="pull-right"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                  Description-{productData.notes}
                  </td>
                </tr>
                <tr>
                  <td>
                    <SpanList name="applications" label="label" className="pill" />
                    <SpanField
                      name="purity"
                      label="label"
                      styleName="pull-right"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <SpanList name="forms" label="label" />
                    <SpanField
                      name="shelf_life"
                      label="label"
                      styleName="pull-right"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <SpanList name="approvals" label="label" />
                    <SpanField
                      name="origin"
                      label="label"
                      styleName="pull-right"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <SpanList name="allergens" label="label" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Footer />
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }
};

export default Product;
