import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import InputTextField from '../../components/FormBuilder/InputComponents/InputTextField';
import InputTextArea from '../../components/FormBuilder/InputComponents/InputTextArea';
import InputSelect from '../../components/FormBuilder/InputComponents/InputSelect';
import InputRadio from '../../components/FormBuilder/InputComponents/InputRadio';
import InputCheckbox from '../../components/FormBuilder/InputComponents/InputCheckbox';
import InputDataTimePicker from '../../components/FormBuilder/InputComponents/InputDataTimePicker';
import InputColorPicker from '../../components/FormBuilder/InputComponents/InputColorPicker'
import InputMultiSelect from '../../components/FormBuilder/InputComponents/InputMultiSelect'
import InputMultiSelectSearch from '../../components/FormBuilder/InputComponents/InputMultiSelectSearch'
import InputMultiFileUpload from '../../components/FormBuilder/InputComponents/InputMultiFileUpload'
import InputFileUpload from '../../components/FormBuilder/InputComponents/InputFileUpload'
import InputMultiRating from '../../components/FormBuilder/InputComponents/InputMultiRating'
import InputRating from '../../components/FormBuilder/InputComponents/InputRating'
import InputTags from '../../components/FormBuilder/InputComponents/InputTags'
import InputExplanatoryText from '../../components/FormBuilder/InputComponents/InputExplanatoryText'
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation'
import InputSlider from '../../components/FormBuilder/InputComponents/InputSlider'
import InputAutoComplete from '../../components/FormBuilder/InputComponents/InputAutoComplete'
import InputSignaturePad from '../../components/FormBuilder/InputComponents/InputSignaturePad'
import InputRichText from '../../components/FormBuilder/InputComponents/InputRichText'
import FormHelper from '../../components/FormBuilder/FormHelpers';
import CommonService from '../../services/CommonService';
//import { useForm } from "react-hook-form";
import Tabs, { TabPane } from 'rc-tabs';
import FormSubmit from '../../components/FormBuilder/FormSubmit'
import ErrorsList from '../../components/FormBuilder/ErrorsList'
import ExitForm from "../../components/Modals/ExitForm";
import PreLoadSpinner from "../../components/PreLoadSpinner";


//let template = {};
let readOnly = true;
let errors = {invalid:{}};
let noOfTabs = 0;
let template = {};

export const DynamicForm = (props) => {
  
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  currentUser.user = currentUser.user || {};
  //const { handleSubmit, register, errors, setValue} = useForm();
  const [activeKey, setActiveKey] = useState('0');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const history = useHistory()

  const forceUpdate = () =>{
    setCount(value => ++value)
  }

  const validationProps = {
    'forceUpdate':forceUpdate,
    'errors':errors
  }

  const onSubmit = (draft, event) => {
    setLoading(true)
    console.log('onSubmit')
    //ct_form.$valid = false;
    event.stopPropagation();

    props.form.updated_by = currentUser.user.id;
    props.form.draft = draft?true:false;
    props.form.data = props.data;

    if(props.formMode == 'create-form-submissions'){
      props.form.created_by = currentUser.user.id;
      props.onCreate(props.form);
     // setLoading(false)
    }else{
      props.onUpdate(props.form);
      //setLoading(false)
    }
  }

  useEffect(() => {
    getFormTemplate();
    return () => {
      template = {};
      noOfTabs = 0;
      readOnly = true;
      errors = {invalid:{}};
    }
  }, [props.formId]);

  const getFormTemplate = () => {
    if(props.formId) {
      CommonService.getFormTemplateById(props.formId).then(function (tf) {
        //template = tf;
        noOfTabs = tf.template_fields.length;
        tf.fill_info = tf.fill_info || 'Please fill out the following fields';
        if(props.formMode == 'create-form-submissions'){
           readOnly = false;
        }else if(props.formMode == 'edit-form-submissions'){
          readOnly = !(props.form.created_by == currentUser.user.id || props.formFn.editPermission);
        }else if(props.formMode == 'view-form-submissions'){
          tf.fill_info = 'View out the following fields';
          tf.post.details ='View Details'
          readOnly = true;
        }
        template = tf;
        props.setPost(tf.post)
      })
    }
    setLoading(false)
  }

  var callback = function(key) {
    setActiveKey(key);
  };

  let nextTab = () =>{
    let i = (parseInt(activeKey) +1).toString();
    setActiveKey(i);
  }

  let previousTab = () =>{
    let i = (parseInt(activeKey)-1).toString();
    setActiveKey(i);
  }

  const ChildElementList = ({childField, pkey}) => childField.map( (field, key )=> {

    let result = FormHelper.checkFieldConditionality(field, props.data);
    if(!result){
      delete errors[field.client_id]
      delete errors.invalid[field.client_id]
      return;
    }

    if(field.required)FormHelper.checkFieldValidity(field, props.data, errors, pkey);

    if(activeKey!=pkey)return; 

    let fieldProps = {
      'field':field, 
      'formData':props.data, 
      'formFn':props.formFn,
      'readOnly':readOnly,
      'position': key
    }

    switch (field.component) {
      case 'textInput':
        FormHelper.onChangeExp(field, props.data);
        return <InputTextField key={key} {...fieldProps} {...validationProps} />
        break;
      case 'textArea':
        return <InputTextArea key={key} {...fieldProps} {...validationProps} />
        break;
      case 'timepicker':
        return <InputDataTimePicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'datepicker':
        return <InputDataTimePicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'checkbox':
        return <InputCheckbox key={key} {...fieldProps} {...validationProps} />
        break;
      case 'radio':
        return  <InputRadio key={key} {...fieldProps} {...validationProps} />
        break;
      case 'select':
        return <InputSelect key={key} {...fieldProps} {...validationProps} />
        break;
      case 'multi_select':
        if(field.is_dynamic_data){
          return <InputMultiSelectSearch key={key} {...fieldProps} {...validationProps} />  
        }else{
          return <InputMultiSelect key={key} {...fieldProps} {...validationProps} />
        }
        break;
      case 'slider':
        return <InputSlider key={key} {...fieldProps} {...validationProps} />
        break;
      case 'autocomplete':
        return <InputAutoComplete key={key} {...fieldProps} {...validationProps} />
        break;
      case 'imageupload':
        return <InputFileUpload key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'multi_file_upload':
        return <InputMultiFileUpload key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'richtext':
        return <InputRichText key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'explanatory_text':
        return <InputExplanatoryText key={key} {...fieldProps} {...validationProps} />
        break;
      case 'color_picker':
        return <InputColorPicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'rating':
        if(field.data_source_id){
          return <InputMultiRating key={key} {...fieldProps} {...validationProps} />
        }else{
          return <InputRating key={key} {...fieldProps} {...validationProps} />
        }
        break;
      case 'input_tags':
        return <InputTags key={key} {...fieldProps} {...validationProps} />
        break;
      case 'signature':
        return <InputSignaturePad key={key} {...fieldProps} {...validationProps} />
        break;
      default:
        return <Fragment key={key}>Not Implemented</Fragment>
      }
    }
  );

  return (
    <form className="row">
      <ErrorsList errors={errors} formFn={props.formFn} setActiveKey={setActiveKey}/>
      {!readOnly && <ExitForm onSubmit={onSubmit}/>}
      <Tabs activeKey={activeKey} onChange={callback} moreIcon="+">
        {template.id && 
          template.template_fields.map((parentField, key) =>(
            <TabPane tab={parentField.label} key={key} forceRender="true">
              <ChildElementList pkey={key} childField={parentField.child_template_fields}/>
            </TabPane>
          ))
        }
      </Tabs>
      <div className="clerfix"></div>
      { loading ? <PreLoadSpinner /> : <FormSubmit errors={errors} form={props.form} readOnly={readOnly} onCancel={props.onCancel} onSubmit={onSubmit} activeKey={activeKey} previousTab={previousTab} nextTab={nextTab} noOfTabs={noOfTabs} formFn={props.formFn} forceUpdate={forceUpdate}>
      </FormSubmit>}
      <small style={{marginLeft:'5px'}}>cT{template.updated_at}-{template.id}</small>
    </form>
  )
    
}

export default DynamicForm;