import React, { Fragment,useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import useStyle from '../../hooks/useStyle';
import CommonService from "../../services/CommonService";
import useEscape from '../../hooks/useEscape';

const GalleryModal = ({  item, type, isOpen, toggleModal, showInline }) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');
  useEscape(toggleModal);
  console.log('toggleModal',item, type, isOpen, toggleModal, showInline)

  let data = item.data
  let images = data.images
  const [currentImage, setCurrentImage] = useState('');
  useEffect(() => {
  
  },);

  const thumbNailImag = images && images.map((im,i)=>{
    console.log('thumb',im,i)
    return(
    <div className="pull-left img-container w-20p m-5">
      <img className="responsive-img w-100p" onClick={(e)=>setCurrentImage(im)} src={im} alt="Nature"/>
    </div>
    )
  })

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          
          <div className="ml-center bg-highlight white p-5 m-b-5">
            <h5>Image Gallery</h5>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" onClick={e=>toggleModal(e)}>×
            </span>
          </div>	
          <div className="row w-100p">
            {thumbNailImag}
          </div>
          { currentImage ? <div className="container img-container">
            <img className="responsive-img product-img p-10" src={currentImage} />
          </div>:''}
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default GalleryModal;