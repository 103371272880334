import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';

const InputTextArea = ({field, formData, readOnly, errors, formFn, isFormWizard}) => {
  const [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
  };

  const setError = (event) => {
    if(field.required){
      const { target:{name, validity:{valueMissing, typeMismatch}} } = event;
      errors[name].invalid = valueMissing;
      errors[name].typeMismatch = typeMismatch;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError)
      formFn.refreshFormSubmit();
    }
  };

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setMinMaxValidation(field, inputAttributes);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  return (
  	<div className="form-group col-xs-12 col-sm-6" title="tooltip">
  	 <label {...labelAttributes}>{field.label}</label>
  	 <textarea {...inputAttributes} onChange={e => handleChange(e)} onBlur={e => {setError(e)}}
     defaultValue={formData[field.client_id]} className="form-control plainTxt2 textAreaht"
     data-tip data-for={`tooltip_${field.client_id}`}/>
 	   <span className="fts-13">{field.description}</span>  
     <Error/>
     <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

export default InputTextArea;