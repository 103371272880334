import env from '../../env';
import axios from 'axios';

class HelpService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}helps`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(id, req){
    console.log('getById help',id,req)
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}helps/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(env.family_tree_backend+'helps', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.family_tree_backend}helps/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.family_tree_backend}helps/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new HelpService();