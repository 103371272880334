import React from "react";
import {Link} from "react-router-dom";
import CommonService from '../../../services/CommonService';

export const DetailList = (props) => {
  let {product, users, currentUser,dataSources} = props;
  let data = product.data
  let ds = product.data_source

  //let sportType = Object.values(dataSources.type_of_sports.options).filter(sports => sports.value == data.sport)
  return (
    <div className="content-boxed shadow-small ht-900" >
      <div className="content vcard-header">
        <ul className="d-flex">
          <li>
          <h1>BOTANICAL NAME</h1>	<label>{data.botanical_name}</label>
          </li>
          <li className="m-l-15">
        
          </li>
        </ul>
      </div>
      <div className="content ht-227">
        <div className="vcard-field">
          <ul class="images-scroll">
          { data.images && data.images.map((item) =>{
          return(<li> <img className="responsive-image" src={item} /></li>)
                  })}
          </ul>
        </div>
        <div className="vcard-field">
          <label>SUPPLIER</label>	Supplier
          <label>PLANT PART </label> 
              {ds.plant_part.map((item) =>{
                return(<li className="pill" key={item.value}>{item.data.label}</li>)
              }
              )}
            <label>CATEGORY</label> 
              {ds.category.map((item) =>{
                return(<li className="pill" key={item.value}>{item.data.label}</li>)
              }
              )}
            <label>APPLICATIONS</label> 
              {ds.applications.map((item) =>{
                return(<li className="pill" key={item.value}>{item.data.label}</li>)
              }
              )}
            <label>GRADE</label> 
              {data.grade}
            <label>PURITY</label> 
              {data.purity}
            
            <label>FORMS</label> 
              {ds.forms.map((item) =>{
                return(<li className="pill" key={item.value}>{item.data.label}</li>)
              }
              )}
            <label>SHELF LIFE</label> 
              <li>{ds.shelf_life.label}</li>
            <label>APPROVALS </label>
              {ds.approvals.map((item) =>{
                      return(<li className="pill" key={item.value}>{item.data.label}</li>)
                    }
                    )}
            <label>ORIGIN </label>
            {ds.origin.label}
            <label>ALLERGENS </label>
            {ds.allergens.map((item) =>{
                    return(<li className="pill" key={item.value}>{item.data.label}</li>)
                  }
                  )}
            <label>PLANT AUDIT </label>
            {ds.plant_audit.label}
              </div>  
      </div> 
        <ul className="vard-footer color-highlight">
         
        </ul>
    </div>
  );
  
};

export default DetailList;