import env from '../env';
import axios from 'axios';
import moment from '../../node_modules/moment/min/moment.min.js'

class CommonService {

  static useAxios(req){
    return new Promise((resolve, reject) => {
      axios(req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getNotifications(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/notifications', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  /*static getDataSources(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_sources', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getDataSourceList(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_source_lists', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static searchDataSource(params){
    return new Promise((resolve, reject) => {	
    	var u = env.ecn_backend_api+'data_source_lists/search'
     	axios.post(u,params).then(function success(res){
     		if(res.status == 200){
       		resolve( res.data );
     		}else{
     			reject()
     		}
     	})
  	})
  }

  static updateDataSourceListStatus(params){
    return new Promise((resolve, reject) => { 
      var u = env.ecn_backend_api+'data_source_lists/status'
      axios.post(u, params).then(function success(res){
        if(res.status == 200){
          resolve( res );
        }else{
          reject()
        }
      })
    })
  }*/

  static getDataSource(params){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'form_templates/options', params).then((res) => {
        // handle success
          resolve(res.data.options);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
  }

	static updateNotification(id, req){
    return new Promise((resolve, reject) => {
      axios.put(env.family_tree_backend+'health/notifications/'+id, req).then((res) => {
        // handle success
          resolve(res);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
	}

	static deleteNotification(req){
	  let url = '?delete_all='+req.delete_all
    return new Promise((resolve, reject) => {
      axios.delete(env.family_tree_backend + 'health/notifications/'+req.id+url).then((res) => {
        // handle success
          resolve(res);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })	
	}

	static uploadFileToUrl(file, privacy_type, data, upload_type, url){
	  return new Promise((resolve, reject) => {
      var fd = new FormData()
    	fd.append('file', file)
    	fd.append('privacy_type', privacy_type)
      if(data){
        for (const property in data) {
          fd.append(`${property}`, data[property])
        }
      }

    	var reader = new FileReader();
    	var image  = new Image();
    	reader.readAsDataURL(file);  

    	reader.onload = function(_file) {
      		image.src    = _file.target.result;   
      		var postImageUploadurl = env.backend+(url || 'file_uploads')

      		if(this.width > 1000){
        		postImageUploadurl = postImageUploadurl+'?max_width=1000'
      		}
      		
      		 axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}})
      		.then(function success(res){
        		try{
              if(upload_type!='comment')res.data.file_upload.url = res.data.file_upload.url.split('?')[0]; 
        			resolve(res.data)
        		}catch(e){
        			reject(res)
        		}
      		}).catch(function error(data){
        		reject(data)
      		});
    	}
      })
  	}

  	static deleteFileFromUrl(url){
  	  return new Promise((resolve, reject) => {	
    	var list = url.split("/");
    	var id = list[6];
    	axios.delete(`${env.backend}file_uploads/${id}`).then(function(res){
    		resolve(res)
    	}).catch(function error(data){
        	reject(data);
      	})
      })	
  	}

  static getFileUrl(file){
    return env.file_url+file; 
  } 

 	static getIconByType(file, type){
    if(file){
      if(file.match(/\.(jpeg|jpg|gif|png|ico)$/) != null){
        if(file.includes('selftree-public')){
          return file;
        }else{
          return env.file_url+file; 
        }
      }else if(file.match(/\.(pdf|PDF)$/)){
        return "/images/pdf_icon.png";
      }else if(file.match(/\.(xls|XLXS|XLS|xlsx|ods)$/) != null){
        return "/images/excel_icon.png";
      }else if(file.match(/\.(doc|docx|odt)$/)){
        return "/images/doc_icon.jpg";
      }else if(file.match(/\.(ppt|pptx|odp)$/)){
        return "/images/ppt.jpg";
      }else if(file.lastIndexOf(".csv")>0){
        return "/images/csv.jpg";
      }else if(file.lastIndexOf(".key")>0){
        return "/images/key.png";
      }else{
        return "/images/attachement-64.png";
      }
    }

    if(type == 'team_journey')return "/images/avatars/2s.png";
    if(type == 'hs_journey')return "/images/avatars/2s.png";
    if(type == 'kids_journey')return "/images/avatars/2s.png";
    if(type == 'mid_journey')return "/images/avatars/2s.png";
  }

  static trimTitle(title){
    if (title.length > 40) {
      return title.substring(0,40) + '...';
   }
   return title
  }

	static getFileName(url){
    	let n = url.lastIndexOf('/');
        let f = url.substring(n+1);
        return f;
  }

  static getFormTemplateById(id){
	  return new Promise((resolve, reject) => {
		  var u = env.backend+'form_templates/'+(id)

		  axios.get(u, {cache: true}).then(function(response){
			 resolve( response.data.form_template );
		  }).catch(function(response){
			 reject(response)
		  });
      
    })
	}

  static getFormWizardFields(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'form_wizard_fields/form_templates', req).then((res) => {
        // handle success
          resolve(res);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
  }

  static getFormWizardSchedules(form_wizard_schedule_id){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+`form_wizard_schedulers/${form_wizard_schedule_id}`).then((res)=>{
        resolve( res );
      }).catch(function(res){
        reject(res)
      })
    })
  }

  static removeUnusedCss(){
    document.head.querySelectorAll(`link[css-remove="true"]`).forEach(function(a){
      a.remove()
    })    
    //document.querySelectorAll('[rel="stylesheet"]');
  }

  static loadCss(file_name){
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', `/styles/${file_name}.css`);
    link.setAttribute('css-remove', "true");
    document.head.appendChild(link);
  }

  static formatUpdateDate(date, format){
    let f = format || 'MMM DD, YYYY';
    if(date)return moment(date).format(f);
  }

  static extractHostName(url){
    if(url && url.length>0){
      let u = document.createElement('a');
      u.href = url;
      return u.hostname;
    }
    return '';
  }

  static deactivate(current_admin){
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}organization_admins/${current_admin.id}/deactivate`).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    })
  }

  static updateSetting(setting){
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}settings/${setting.id}`, setting).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

  static groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  static b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
     }));
  }

  static b64DecodeUnicode(str) {
     // Going backwards: from bytestream, to percent-encoding, to original string.
     return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
     }).join(''));
  }

  static subString(str){
    return str && str.substring(0,200)
  }

  static routeJourney(journeyProfile, props){
    if(journeyProfile.data.fj_type_edu_jou == 14){
      /*Team journey template*/
      props.history.push(`/team/project/portal/${journeyProfile.journey_category_id}`)
    }else if(journeyProfile.data.fj_type_edu_jou == 1){
      /*Kids journey*/
    }else if(journeyProfile.data.fj_type_edu_jou == 2){
      /*Mid School journey*/
    }else if(journeyProfile.data.fj_type_edu_jou == 3){
      props.history.push(`/highschool/project/portal/${journeyProfile.journey_category_id}`)
      /*High School journey*/
    }
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static linkify(inputText) {
    try{
      let replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

      return replacedText;
    }catch(e){
      return inputText;
    }
  }

  static getFileUploads(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'file_uploads', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getJourneyHyperLinks(req){
    return new Promise((resolve, reject) => {
      axios.get(env.family_tree_backend+'journey_links', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    }) 
  }

  static getIncomingMails(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'incoming_mails', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    }) 
  }

  static deleteIncomingMails(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.backend}incoming_mails/${req}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getRatingById(id){
    return new Promise((resolve, reject) => {
      axios.get(env.family_tree_backend+'ratings/'+id).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
  static createRating(req){
    return new Promise((resolve, reject) => {
      axios.post(env.family_tree_backend+'ratings', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static updateRating(req, id){
    return new Promise((resolve, reject) => {
      axios.put(env.family_tree_backend+`ratings/${id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
}

export default CommonService;