import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'


const chartData = [
    { label:"fever",value: 14, time: 1503617297689 },
    { label:"fever",value: 15, time: 1503616962277 },
    { label:"fever",value: 15, time: 1503616882654 },
    { label:"fever",value: 20, time: 1503613184594 },
    { label:"fever",value: 15, time: 1503611308914 },
  ]

const TimeSeriesChart = ({ }) => (
  <ResponsiveContainer width = '95%' height = {500} >
    <ScatterChart>
      <XAxis
        dataKey = 'time'
        domain = {['auto', 'auto']}
        name = 'Time'
        tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')}
        type = 'number'
      />
      <YAxis dataKey = 'value' name = 'Value' />
      <Tooltip mode= 'nearest'/>
      <Scatter
        data = {chartData}
        line = {{ stroke: 'black' }}
        lineJointType = 'monotoneX'
        lineType = 'joint'
        name = 'Values'
      />

    </ScatterChart>
  </ResponsiveContainer>
)


export default TimeSeriesChart