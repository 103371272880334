import React, {useState, Fragment} from 'react';

function FormSubmit({form, onCancel, onSubmit, activeKey, previousTab, nextTab, noOfTabs, formFn, readOnly, errors, forceUpdate}) {
    const [count, setCount] = useState(0);

  	formFn.refreshFormSubmit = () =>{
    	setCount(value => ++value)
  	}

    const handleSubmit = (e) =>{
      if(Object.keys(errors.invalid).length === 0){
        onSubmit(false, e)
      }else{
        errors.isSubmitted = true;
        forceUpdate();
        setTimeout(function() {
          let ele = document.getElementById('error_list')
          ele.scrollIntoView();
        }, 0); 
        //formFn.refreshErrorsList();
      }
    }

    return (
    	<Fragment> { !readOnly && 
        <div className="col-xs-12 text-center">
          <button type="button" onClick={onCancel} className="dropbtn bg-lightblue" >CANCEL</button>
          <button type="button" onClick={e=>onSubmit(true, e)} className="dropbtn bg-lightblue" >SAVE DRAFT</button>
          <button type="button" onClick={e=>handleSubmit(e)} className="dropbtn bg-lightblue">

            {form.id?'UPDATE':'SUBMIT'} 
          </button>
        </div>}
        <div className="col-xs-12">
          {activeKey>0 && <button type="button" onClick={e=>{previousTab()}} className="btn btn-inverse btn-rounded pull-left">
          <i className="fa fa-chevron-left" aria-hidden="true"></i></button>}
          {(activeKey < (noOfTabs-1)) && <button type="button" onClick={e=>{nextTab()}} className="btn btn-inverse btn-rounded pull-right">
          <i className="fa fa-chevron-right" aria-hidden="true"></i></button>}
        </div>
      </Fragment>
  	)
}

export default FormSubmit;