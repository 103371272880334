import { useEffect } from 'react';

const useStyle = (resourceUrl) => {
    useEffect(() => {
     /* return new Promise((resolve, reject) => {*/
      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      /*link.onload = function(){ 
        resolve();      
      }
      link.onerror = function(){
        reject();
      }*/
      link.setAttribute('href', `/styles/${resourceUrl}.css`);
      document.head.appendChild(link);
      /*})*/
    return () => {
        document.head.removeChild(link);
      }
    }, [resourceUrl]);

};

export default useStyle;