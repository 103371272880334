import { useState } from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(e) {
  	e.preventDefault();
    setIsOpen(!isOpen);
  }

  return {
    isOpen,
    toggleModal,
  }
};

export default useModal;