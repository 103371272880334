import React from "react";

export const PreLoadSpinner = () => {
  return (
    <div id="page-preloader">
      <div className="loader-main">
        <div className="preload-spinner border-highlight" />
      </div>
    </div>
  );
};

export default PreLoadSpinner;