import React from 'react';
import ReactDOM from 'react-dom';

const ConfirmMenu = ({ isOpen, toggleModal, success }) => isOpen ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="menu-hider menu-active" style={{ display: "block", transform: "translate(0px, 0px)" }}/>;  
    <div id="menu-confirm" className="menu menu-box-modal round-medium menu-active modal-xs">
        <h1 className="center-text uppercase ultrabold top-30 font-16">Are you sure?</h1>
        <p className="boxed-text-large font-15">
            Want to delete this item?
        </p>
        <div className="content left-20 right-20">
            <div className="one-half">
                <a href="#" className="close-menu button button-center-large button-s shadow-large button-round-small bg-blue-dark"
                onClick={ e => {success(e)} }>
                    YES
                </a>
            </div>
            <div className="one-half last-column">
                <a href="#" className="close-menu button button-center-large button-s shadow-large button-round-small bg-black"
                onClick={ e => {toggleModal(e)} }>
                    NO
                </a>
            </div>
            <div className="clear" />
        </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default ConfirmMenu;