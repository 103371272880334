import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import CommonService from "../services/CommonService";

const PrivateRoute = ({component: Component, ...rest}) => {
	const { state:authState, dispatch } = React.useContext(AuthContext);
    
   /* CommonService.loadCss('forms');
    CommonService.removeUnusedCss();*/
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (authState.isAuthenticated ? <Component {...rest} {...props} />: <Redirect to="/signin" />)} />
    );
  
};

export default PrivateRoute;
