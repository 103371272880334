import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import querystringify from "querystringify"
import DynamicForm from '../FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import FormWizard from '../FormBuilder/FormWizard';
import env from '../../env';

let formMode = null; 
let formId = null; 
let buyer = {}; 
let data = {}; 
let formFn = {};  

function BuyerForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
    
  /*TODO: Move API to Router to load data before view
  const getById = () => {
    SupplierService.getById(params.id).then((res) => {
      if(res.status==200){
        supplier = res.data.supplier[0];
        data = supplier.data || {};
        setFormRendered(true);
      }
    }).catch(function(res){
       supplier = null;
    })
  }
  
  */
  useEffect(() => { 
    formMode = 'create-form-submissions';
    data = {}; 
    buyer = {}; 
    formFn = {'form_type':'buyer'};
   
      setFormRendered(true);
    //setFormRendered(true);
  }, []);
    

  const create = (req) => {
    onSubmitPageRedirect();
  }

  const update = (req) => {
    onSubmitPageRedirect();
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/dashboard`)  
  }

  const back = () => {
    props.history.goBack();
  }

  if(isFormRendered){
    return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h4 className="portlet-title text-uppercase">
          {post.title}
        </h4>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <div className="form-details m-t-10" dangerouslySetInnerHTML={{ __html: post.details }}></div>
          <DynamicForm formMode={formMode} formId={FormTemplateId.BuyerForm} form={buyer} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={back} 
          setPost={setPost} />
        </div>
      </div>
    </div>
    );  
  }else{
    return null;
  }
  
}

export default BuyerForm;