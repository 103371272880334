import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers';
/*import useCustomForm from '../../../hooks/useCustomForm';*/

const InputTextField = ({field, formData, formFn, readOnly, errors, isFormWizard, position}) => {
  
  /*let validator = {
    set: function(obj, prop, value) {
      if (field === 'age') {
        if (!Number.isInteger(value)) {
            throw new TypeError('The age is not an integer');
        }
        if (value > 200) {
            throw new RangeError('The age seems invalid');
        }
      }

      // The default behavior to store the value
      obj[prop] = value;

      // Indicate success
      return true;
    }
  };

  let modal = new Proxy(formData, validator);*/

  const [showError, setShowError] = useState(false);

  let inputAttributes = {
  	'id': field.client_id,
  	'name': field.client_id,
  	'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'type':'text',
    'required':field.required
  }

  let parentAttributes = {
  	'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    required: 'This is required'
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
    FormHelper.setFormTitle(field, formData, target.value)
    //setValue("lastName", "Hopper")
  };

  const setError = (event) => {
    if(field.required){
      const { target:{name, validity:{valueMissing, typeMismatch}} } = event;
      errors[name].invalid = valueMissing;
      errors[name].typeMismatch = typeMismatch;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  };

  FormHelper.setInputTypeValidation(field, inputAttributes, validation, position);
  FormHelper.setMinMaxValidation(field, inputAttributes, validation);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          {errors[field.client_id].invalid && <div>
            {field.label} is required.
          </div>}
          {errors[field.client_id].typeMismatch && <div>
            {field.label} is invalid.
          </div>}
        </div>
      )
    }else{
      return null
    }
  }

  return (
  	<div {...parentAttributes}>
  	  <label {...labelAttributes}>{field.label}</label>
 	    <input {...inputAttributes} data-tip data-for={`tooltip_${field.client_id}`} onChange={e => handleChange(e)} defaultValue={formData[field.client_id]} 
      onBlur={e => {setError(e)}} className="form-control"/> 	 
  	  <span className="fts-13">{field.description}</span>
 	    <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

export default InputTextField;