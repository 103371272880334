import React, { Fragment, useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import useStyle from "../../hooks/useStyle";
import HelpSideNav from "../../components/Help/HelpSideNav";
import NotesModal from "../../components/Modals/NotesModal";
import useModal from "../../hooks/useModal";

const SideMenuBar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {
    isOpen: isPageNoteOpen,
    toggleModal: togglePageNoteModal,
  } = useModal();
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const openFeedback = (e) => {
    history.push(`/feedback?url=${location.pathname}`);
  };

  if (isSideMenuOpen) {
    let style = { padding: "15px", borderRadius: "10px" };
    return ReactDOM.createPortal(
      <Fragment>
        <div className="side-menu-bar" style={style}>
          <div onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
            <i className="fas fa-arrow-right"></i>
          </div>
          <div onClick={(e) => setIsHelpOpen(!isHelpOpen)}>Help</div>
          <div onClick={(e) => openFeedback(e)}>Feedback</div>
          <div onClick={(e) => togglePageNoteModal(e)}>My Notes</div>
        </div>
        {isPageNoteOpen && <NotesModal toggleModal={togglePageNoteModal} />}
      </Fragment>,
      document.body
    );
  } else {
    let style = { padding: "5px" };
    return ReactDOM.createPortal(
      <div
        className="side-menu-bar"
        style={style}
        onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}
      >
        <i className="fas fa-arrow-left"></i>
      </div>,
      document.body
    );
  }
};

export default SideMenuBar;
