import React from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';

const InputExplanatoryText = ({field, isFormWizard}) => {
  
  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  /*const SetError = () => 
    <div className="errormsg">{errors[inputAttributes.name] && errors[inputAttributes.name].message}</div>*/
    
  return (
  	<div className="form-group col-xs-12" {...parentAttributes}>
  	  <label {...labelAttributes}>{field.label}</label>
      <div dangerouslySetInnerHTML={{ __html: field.description_text }} />
 	  </div> 
  )
}

export default InputExplanatoryText;