import React, {Fragment} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import { Link } from "react-router-dom";

export const ForgetPassword = (props) => {
  useStyle('signup');

  const { state: authState, dispatch } = React.useContext(AuthContext);

  const initialState = {
    email: "",
    isSubmitting: false,
    errorMessage: null,
    isEmailSent:false
  };

  const [data, setData] = React.useState(initialState);

  const handleInputChange = (event) => {
    console.log("datafp", data);
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/')
    }
  }, [authState.isAuthenticated]);

  const handleForgetSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    console.log("handle state forsubmit", data);
    let req = { user: { email: data.email } };
    AuthenticationService.forgotPassword(req)
      .then((res) => {
        console.log("resforgot", res);
        if (res.status == 200) {
          setData({
            ...data,
            isSubmitting: false,
            isEmailSent:true
          });
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: res.message || res.statusText,
          });
        }
      })
      .catch((error) => {
        console.log("error fp", error);
        setData({ ...data, errorMessage: error.response.data.error });
      });
  };

  return (
    <React.Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20 ht-150" >
          {/* image */}
        </div>
        <div
          className="page-title-bg dark-mode-tint ht-150"
          
        >
          {/* contrast for dark mode */}
        </div>
        <div
          className="page-title-bg opacity-90 bg-highlight ht-150"
          
        >
          {/* background color */}
        </div>
        <div className="page-title-small color-white bottom-30">
          <h1>
            Forgot{" "}
          </h1>
        </div>
        <form className="signform">
        <div className="content-boxed shadow-small p-b-5">
          <div className="content">
            <h4>Reset your Account</h4>
            {data.isEmailSent && <p>Password reset link sent.</p>}
              
            {!data.isEmailSent && <Fragment>
              <p>
                Simply enter your email name which you registered your account
                under and will send you the password reset instructions.
              </p>
              <div className="input-style has-icon input-style-1 input-required">
                <i className="input-icon fa fa-at" />
                <span>Email</span>
                <em>(required)</em>
                <input type="email" placeholder="Email" name="email" onChange={handleInputChange}/>
              </div>
              <button onClick={handleForgetSubmit}
                className="button button-full button-m shadow-large button-round-small bg-highlight top-30 bottom-0">
                SEND RESET INSTRUCTIONS
              </button>
              </Fragment>
            }
          </div>
        </div>
        <Link to="/signin"
          className="button button-m button-full round-small button-margins bg-green1-dark bottom-30 shadow-samll">
          Want to Sign In? Click Here
        </Link>
        </form>
        
        {/* Footer */}
        <div className="signinbg">
        <div className="signincon">
          <img src={'./../../../images/cueTree_logo.png'} style={{display: 'block',marginLeft: 'auto',marginRight: 'auto',width: '50%'}} />
          <div className="font-500 font-16">COMMERCE</div>
        </div>
        <div className="signcopy hidden-xs">
        <i className="far fa-copyright"></i> 2021 IPIPAL INC. All Rights Reserved.</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
