import env from '../env';
import axios from 'axios';
import CommonService from './CommonService'
const url = `${env.health_backend}/health/comments`

class CommentsService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        res.data.comments.forEach((item)=>{
          if(!item.file_upload_id){
            item.comment = CommonService.linkify(item.comment)
          }
        })

        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  add(note){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, note).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(note){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${note.id}`, note).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new CommentsService();