import React from 'react';
import ReactTooltip from 'react-tooltip';

const CueTooltip = (props) => {
	return(
	  <ReactTooltip id={`tooltip_${props.id}`} place="bottom" type="info" effect='solid'>
        <span dangerouslySetInnerHTML={{ __html: props.description }}></span>
      </ReactTooltip>
	)
}

export default CueTooltip;