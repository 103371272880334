import React, {useState, useEffect} from "react"
import importScript from '../../hooks/importScript'
import env from '../../env'

let url = env.socket;
let socket = {}

const Socket = (currentUser) => {
    
    let [isConnected, setIsConnected] = useState(false);
    
    useEffect(() => {
        if(!isConnected)importScript('/scripts/faye.js', init);
        return () => {
            socket.subscription.cancel();
            socket.fayeClient.disconnect();
        }
    }, []);

    function init(){
        if(!currentUser)return;
        let sender_channel = '/notification_channel_'+currentUser.id;
        socket.fayeClient = new window.Faye.Client(url, {timeout: 120, retry: 3});
        //client.disable('in-process');
        //client.disable('long-polling');
        //client.disable('callback-polling');
        setIsConnected(true);
        socket.subscription = socket.fayeClient.subscribe (sender_channel, function (res) {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    if(res.notification_type == 'chat'){
                        let notification = new Notification('Chat', {
                            body: res.data.message,
                            icon: 'images/cueTree_logo.png'
                        })
                    }else{
                        let notification = new Notification(res.data.message, {
                            body: res.data.email_data.title,
                            icon: 'images/cueTree_logo.png'
                        })
                    }  
                }
            })
        })

        /*$scope.$on($scope.channel, function(event, data){
            fayeClient.publish($scope.channel, data);
        })

        $scope.$on('$destroy', function() {
            subscription.cancel();
            fayeClient.disconnect();
        })*/
    }
}

export default Socket;