  import { useHistory } from "react-router-dom";

  export default (state, action) => {
    let history = useHistory();

    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.data.user,
        };
      case "LOGOUT":
        history.push('/signin');
        return {
          ...state,
          isAuthenticated: false,
          user: null
        };
      case "UPDATE":
        return {
          ...state,
          user: action.payload.data.user,
        };
      case "TOGGLE_PAGE_LOADER":
        return {
          ...state,
          showPageLoader:action.payload.showPageLoader
        };
      default:
        return state;
    }
  };