import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';

const InputMultiRating = ({field, formData, readOnly, errors, formFn, isFormWizard}) => {
  let [model, setModel] = useState(formData[field.client_id] || {});
  let [showError, setShowError] = useState(false);
  let [sumOfRating, setSumOfRating] = useState(formData['sum_of_'+field.client_id] || 0);
  
  let totalRating = 0;

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let validation = {
    required: field.required
  }

  let op_kv = field.options_key_value;
  if(op_kv.options && op_kv.options.length>0){
    totalRating = op_kv.options.length*5;
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const bindWindowClickEvent = () =>{
    let el = document.getElementById(field.client_id+'_result_set');
    el.classList.remove("hidden");
    //$scope.onOpen({req:$scope.model});
    window.addEventListener('click',eventFunction)
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field)
      }else{
        let el = document.getElementById(field.client_id+'_result_set');
        el.classList.add("hidden");
        window.removeEventListener("click",eventFunction);
        //$scope.onClose({req:$scope.model});
      }
    }catch(e){
    
    }
  }

  const toggle = (item, value) => {
    model = model || {};
    if(model[item.value] == value){
      delete model[item.value];
    }else{
      model[item.value] = value;
    }
  
    setModel(model);
    setSumOfRating(sum(model));

    formData[field.client_id] = model;
    formData['sum_of_'+field.client_id] = sumOfRating;
    setError();
  }

  const sum = (obj) => {
    let sum = 0;
    for( let el in obj ) {
      if( obj.hasOwnProperty( el ) ) {
        sum += parseFloat( obj[el] );
      }
    }
    return sum;
  }
  
  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = Object.keys(formData[name]).length === 0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }
  
  const ratings = op_kv.options && op_kv.options.map((o, k) =>  
    <div key={k} title={o.hover_text}>
      {o.label}
      <span className="pull-right font-20">
        <i className={`${1<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(o,1)}></i>
        <i className={`${2<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(o,2)}></i>
        <i className={`${3<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(o,3)}></i>
        <i className={`${4<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(o,4)}></i>
        <i className={`${5<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(o,5)}></i>
      </span>
    </div>
  )

  return (
  	<div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
  	  <label {...labelAttributes}>{field.label}</label>
 	    <div className="autocomplete" id={`${field.client_id}_container`} onClick={e => bindWindowClickEvent(e)} >
        <input {...inputAttributes} className="form-control" placeholder="Enter Search txt"
        value={`${sumOfRating} out of ${totalRating}`} readOnly />
        <div id={`${field.client_id}_result_set`} className="autocomplete-items hidden">
          {ratings}
        </div>  
       </div>
  	  <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
 	  </div> 
  )

}

export default InputMultiRating;