import React ,{Fragment, useEffect, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../services/CommonService';
import env from '../../../src/env';
import useStyle from '../../hooks/useStyle';

const Notification = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  let {notification, onDelete} = props;
  let profileImage = 'images/avatars/2s.png'
  notification.updated_at = CommonService.formatUpdateDate(notification.updated_at);
  if(notification.sender && notification.sender.profile_image_url){
    profileImage = env.file_url+notification.sender.profile_image_url;
  }

  return(
    <Fragment>  
    <i className="pull-right fa fa-times lred" onClick={e=>props.onDelete(e, notification)}/>
    <div className="d-flex" onClick={e=>props.routeNotification(notification, e)}>
      <div>
        <img src={profileImage} className="rounded-circle bg-lgrey-2" width="50"/>
      </div>
      <div className="m-l-10">  
        <h5 className="d-title">{notification.journey_profile_name} - {notification.email_data && notification.email_data.type}</h5>
        <p className="d-description">{notification.email_data && notification.email_data.description}</p>
        <p className="d-description">{notification.sender_name} at {CommonService.formatUpdateDate(notification.updated_at)}</p>
      </div>
    </div>
    <div className="divider"/>
  </Fragment>
  )
}

export default Notification;

/*<img src="images/preload-logo.png" width={15} className="rounded mr-2" alt="..."/>*/