import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from "../../routes/PrivateRoute";
import ProductList from './List/ProductList'
import BuyerForm from '../Form/BuyerForm'
import Details from './List/Details'
import ProductOrderForm from './Form/ProductOrderForm'
import ProductInquiryForm from './Form/ProductInquiryForm'

const ProductRouter	 = (props) => (
  <React.Fragment>	
    <Switch>
      <PrivateRoute path="/buyer/form" component={BuyerForm} exact />
      <PrivateRoute path="/products" component={ProductList} exact />
      <PrivateRoute path="/product/details/:id?" component={Details} exact />
      <PrivateRoute path="/product/orderForm/:product_id/:id?" component={ProductOrderForm} />
      <PrivateRoute path={["/product/requestInfo/:inquiry_type/:product_id/:template_id/:id?","/product/requestSample/:inquiry_type/:product_id/:template_id/:id?"]} component={ProductInquiryForm} />
    </Switch>
  </React.Fragment>
)

export default ProductRouter	;