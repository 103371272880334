import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LineChart from "../Charts/LineChart"
import CommonService from "../../services/CommonService";
import env from "../../env";

const TradeReport = (props) => {

  let [metricData, setMetricData] = useState({})
  let params = props.match.params;

  useEffect(() => {
    getTradeReport();
  }, []);

  let getTradeReport = () => { 
    let req = {
      method: "post",
      url: `${env.commerce_backend}commerce/trade`,
      data: {
        'type':["origin", "destination", "date", "quantity"]
      }
    }

    CommonService.useAxios(req).then((res) => {
      setMetricData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  let countryOfOrigin = {
    id:"chart_1",
    series: [{data: metricData.origin}],
    type : 'bar',
    title : {text: 'Top Supplier', align: 'left'},
    xasis: "text",
    yaxis : {
      title: {
        text: 'No.of supply'
      },
      type: 'numeric',
      min:1,
      max:10,
      labels: {
        show: true,
        formatter: function (val, opts) {
          return val.toFixed(0);
        }
      }
    },
    stroke:{}
  }
  
  let destination = {
    id:"chart_1",
    series: [{data: metricData.destination}],
    type : 'bar',
    title : {text: 'Top Destination', align: 'left'},
    xasis: "text",
    yaxis : {
      title: {
        text: 'No.of supply'
      },
      type: 'numeric',
      min:1,
      max:10,
      labels: {
        show: true,
        formatter: function (val, opts) {
          return val.toFixed(0);
        }
      }
    },
    stroke:{}
  }

  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Trade Report</h5>
        <div className="clear"></div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          {metricData.origin && <LineChart {...countryOfOrigin}/>}
        </div>
        
        <div className="col-xs-12">
          {metricData.destination && <LineChart {...destination}/>}
        </div>
      </div>
    </Fragment>
  )

}

export default TradeReport;