import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import CommonService from '../../../services/CommonService';

const InputMultiFileUpload = ({field, formData, readOnly, errors, formFn, isFormWizard, formId}) => {
  if (typeof formData[field.client_id] === 'string') {
    formData[field.client_id] = [formData[field.client_id]]
  }

  formData[field.client_id] = formData[field.client_id] || [];
  
  const [model, setModel] = useState(formData[field.client_id]);
  const [showError, setShowError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  
  let inputAttributes = {
    'type':'file',
    'id': 'multi_upload_selector_'+field.client_id,
    'name': field.client_id,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {
    required: field.required
  }

  let privacyType = field.privacy_type || 'private';
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  const onChange=event=>{
    uploadMultiFileToUrl(event.target.files, event.target)
  }

  const uploadMultiFileToUrl = (files, ele) => {
    try {
      var FileSize = files[0].size / 1024 / 1024; // in MB
      if (FileSize > 2) {
        ele.value = null
        setFileSizeError(true)
        return
      }else{
        setFileSizeError(false)
      }

     if(files.length>0){
        setShowUploadProgress(true)
        let imageData = {
          'fileable_type':formFn.form_type, 
          'fileable_id':formId,
          'journey_category_id':formFn.journey_category_id
        }
        for (let file of files) {
          CommonService.uploadFileToUrl(file, privacyType, imageData).then((data)=> {
            setModel([...model, data.file_upload.url])
            formData[field.client_id].push(data.file_upload.url);
            ele.value = null;
            setShowUploadProgress(false)
            setError();
          }, (err)=>{
            setShowUploadProgress(false)
          })
        }
      }
    } catch (e) {
      setShowUploadProgress(false)
      console.log(e)
    }
  }

  const removeImageFromList = (file, idx, event) => {
    try {
      event.stopPropagation();
      CommonService.deleteFileFromUrl(file).then(function(res) {
        if(res.status == 200){
          setModel(model.filter(i => i !== file));
          formData[field.client_id].splice(idx, 1);
          setError();
        }
      })
    } catch (e) {
      alert(e);
    }
  }

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = model.length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  const MaxFileSizeError = () => {
    if(fileSizeError){
      return(
        <div className="errormsg">
          <div>File Size is Large. Max Size 2MB.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const ImageList = model.map((file, k) =>  
    <div className="upload-sm-card" key={k}>
      <span className="upload-delete" onClick={e => removeImageFromList(file, k, e)}>×</span>
      <div>
        <a href="#">
          <div>
            <img src={CommonService.getIconByType(file)} alt="img" className="w-85" alt="img"/>
            <strong>{CommonService.getFileName(file)}</strong>
          </div>
        </a>
      </div>
    </div>
  )
  
  return (
  	<div {...parentAttributes}>
  	  <label {...labelAttributes}>{field.label}</label>
 	    {!showUploadProgress &&
        <label ng-hide="edit_row" className={`btn btn-default w-100p bg-highlight color-white ${readOnly && 'input-readonly'}`} htmlFor={`multi_upload_selector_${field.client_id}`}
        data-tip data-for={`tooltip_${field.client_id}`}> 	 
  	     Click to Upload File
        </label>
      }
      {showUploadProgress &&
        <label className="btn btn-default w-100p bg-highlight color-white" >   
          Uploading...
        </label>
      }
      <input {...inputAttributes} className="hidden" onChange={e => onChange(e)} multiple />
      {ImageList}
      <span className="fts-13">{field.description}</span>
      <Error/>
      <MaxFileSizeError/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
 	  </div> 
  )
}

export default InputMultiFileUpload;

/* <div className="col-xs-6" key={k}>
      <div className="thumbnail">
        <span className="close" onClick={e => removeImageFromList(file, k, e)}>×</span>
         <a href={file} target="_blank">
          <img src={CommonService.getIconByType(file)} className="thumb-md"/>
        </a>
        <span>{CommonService.getFileName(file)}</span>
      </div>
    </div>*/