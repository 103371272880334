import React, { useEffect, useState, useContext, Fragment} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

import useStyle from '../../hooks/useStyle';
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import env from '../../env';


const Dashboard = (props) => {
  useStyle('dashboard');
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [visible, setVisible] = useState(true);
  const [journeyList, setJourneyList] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [schedulesList, setScheduleList] = useState([]);
  let supplier = currentUser.supplier
  /*const activities = () =>{
    let req = {'accelerator_id': currentUser.accelerator.id}
    AcceleratorService.activities(req).then((res)=>{
      setData(res.data.activities);
    })
  }*/

  useEffect(() => {

  }, [currentUser.id])


  const NotificationView =() => {return (
  <Fragment>  
    <div className="d-flex pointer">
      <div>
        <img className="rounded-circle bg-lgrey-2" width="50"/>
      </div>
      <div className="m-l-10">  
        <h5 className="d-title"></h5>
        <p className="d-description"></p>
        <p className="d-description "> <span className="lred"></span></p>
      </div>
      <div className="ml-auto">
         
      </div>
    </div>
    <div className="divider"/>
  </Fragment>
  )}

  const CueNewView = () => {  
  return(
  <Fragment>  
    <div className="d-flex pointer" >
      <div>
        <img src="images/news-50.png" className="rounded-circle" width="50"/>
      </div>
      <div className="m-l-10">  
        <h5 className="d-title"></h5>
        <p className="d-description"></p>
        <p className="d-description lred"></p>
      </div>
      <div className="ml-auto">
         
      </div>
    </div>
    <div className="divider"/>
  </Fragment>
  )}

  const JourneyView = ()=>  {
    return (
  <Fragment>  
    <div className="d-flex pointer">
      <div>
        <img src="images/avatars/2s.png" className="rounded-circle bg-highlight" width="50"/>
      </div>
      <div className="m-l-10">  
        <h5 className="d-title">
             </h5>
        <p className="d-description lred"></p>
        <p className="d-description"></p>
      </div>
      <div className="ml-auto">
         
      </div>
    </div>
    <div className="divider"/>
  </Fragment>
  )}

  const SchedulesView = () => {
    return (
    <Fragment>  
    <div className="d-flex pointer">
      <div>
        <img src="images/avatars/2s.png" className="rounded-circle bg-highlight" width="50"/>
      </div>
      <div className="m-l-10">  
        <h5 className="d-title"></h5>
        <p className="d-description"></p>
      </div>
      <div className="ml-auto">
         
      </div>
    </div>
    <div className="divider"/>
  </Fragment>
  )}

  /*if(newsList.length == 0 && schedulesList.length == 0 && journeyList.length == 0 && notifications.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  return (
    <Fragment>
    
      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to={`/products`} className="head-boder">
              <h3> Recommended Products</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <NotificationView />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="">
              <h3 className="head-boder">
                 My Orders</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <SchedulesView />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="" className="head-boder">
              <h3> Best Selling Products</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <JourneyView />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home">
            <Link to="" className="head-boder">
              <h3>Request Information and Sample</h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <CueNewView />
          </div>
        </div>
      </div>
      <ReactTooltip place="left" />

    </Fragment>
  )
}

export default Dashboard;