import React, { Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AuthenticationService from "../../services/AuthService";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const SideMenu = (props) => {
  const { state: { screen, user: currentUser }, dispatch,} = React.useContext(AuthContext);
  const sideNavEl = useRef(null);
  const sideNavMenuEl = useRef(null);

  const signout = (e) => {
    e.stopPropagation();
    e.preventDefault();
    AuthenticationService.signout().then(() => {
      dispatch({ type: "LOGOUT" });
    })
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClick, true);
    document.addEventListener("touchend", handleClick, true);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClick, true);
      document.removeEventListener("touchend", handleClick, true);
    }
  }, []);

  const handleEscape = (e) => {
    if (e.keyCode === 27) props.setOpenSideNav(false);
  };

  const handleClick = (e) => {
    if (
      sideNavEl.current &&
      (!sideNavEl.current.contains(e.target) ||
        sideNavMenuEl.current.contains(e.target))
    ) {
      props.setOpenSideNav(false);
    }
  };

  return (
    <div
      id="menu-main"
      ref={sideNavEl}
      className={`menu menu-box-left menu-box-detached round-medium ${
        props.openSideNav ? "menu-active" : ""
      } `}
      style={{ width: 260, opacity: 1, display: "block" }}
    >
      <div className="menu-icons">
        <a href="#" data-toggle-theme>
          <i className="fa fa-lightbulb color-yellow1-dark" />
        </a>
        <a href="#" data-menu="menu-highlights">
          <i className="fa fa-brush color-green1-dark" />
        </a>
        <a href="#" data-menu="menu-share">
          <i className="fa fa-share-alt color-orange-dark" />
        </a>
        <Link
          to="/user/profile"
          onClick={(e) => props.setOpenSideNav(!props.openSideNav)}
        >
          <i className="fa fa-cog color-blue2-dark" />
        </Link>
        <a
          href="#"
          className="close-menu"
          onClick={(e) => {
            e.preventDefault();
            props.setOpenSideNav(!props.openSideNav);
          }}
        >
          <i className="fa fa-times color-red2-dark " />
        </a>
        <div className="clear" />
      </div>

      <div className="menu-items">
        <div className="center-text">
          <a
            href="#"
            className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook"
          >
            <i className="fab fa-facebook-f" />
          </a>
          <a
            href="#"
            className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            href="#"
            className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram"
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            href="#"
            className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin"
          >
            <i className="fab fa-linkedin-in" />
          </a>
          <a
            href="#"
            className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp"
          >
            <i className="fab fa-whatsapp" />
          </a>
        </div>
        <div className="menu-header">
          <a href="#" className="shadow-small" />
          <h1 className="center-text font-800 font-28 line-height-medium uppercase">
            Journeys
          </h1>
          <p className="under-heading center-text font-11">
            Guided Experiences
          </p>
        </div>
        <div className="content top-20 bottom-10">
          <div className="link-list link-list-1" ref={sideNavMenuEl}>
            <Link id="nav-welcome" to="/dashboard">
              <i className="fa fa-home color-blue2-dark" />
              <span>Home</span>
              <i className="fa fa-angle-right" />
            </Link>

            <Link id="nav-welcome" to="/products">
              <i className="fa fa-desktop color-blue2-dark" />
              <span>My Products</span>
              <i className="fa fa-angle-right" />
            </Link>

            <Link id="nav-contact" to="/myOrders">
              <i className="fa fa-users color-blue2-dark" />
              <span>My Orders</span>
              <i className="fa fa-angle-right" />
            </Link>

            <Link id="nav-contact" to="/mySamples">
              <i className="fas fa-calendar-alt color-blue2-dark" />
              <span>My Samples</span>
              <i className="fa fa-angle-right" />
            </Link>

            <Link id="nav-contact" to="/myRFI">
              <i className="fa fa-globe color-blue2-dark" />
              <span>My RFI</span>
              <i className="fa fa-angle-right" />
            </Link>

            <a id="nav-chat" href="/" onClick={(e) => props.toggleChatModal(e)}>
              <i className="fas fa-comments color-blue2-dark" />
              <span>Chat</span>
              <i className="fa fa-angle-right" />
            </a>

            <Link id="nav-roles" to="/buyer/form">
              <i className="fa fa-user color-blue2-dark" />
              <span>Profile</span>
              <i className="fa fa-angle-right" />
            </Link>

            <a href="/" onClick={(e) => signout(e)} className="">
              <i className="fa fa-times color-red2-dark " />
              <span>Sign Out</span>
            </a>
          </div>
          <div className="center-text bottom-75 m-t-10">
            <a
              href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter"
            >
              <i className="fab fa-twitter" />
            </a>
            <a
              href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin"
            >
              <i className="fab fa-linkedin-in" />
            </a>
            <a
              href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp"
            >
              <i className="fab fa-whatsapp" />
            </a>
            <p className="top-10 font-10 opacity-50">
              <i className="fa fa-copyright" aria-hidden="true"></i>
              <span className="copyright-year">2021</span> IPIPAL Inc. All
              rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;