import React , {useState, Fragment}from "react";
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../../services/CommonService';
import ProductCommerceService from "../ProductCommerceService";
import DetailList from './DetailList'
let requestParams = {}
let selectedJourney = {};
let data_source = {};
let users = {};

export const Details = (props) => {
  console.log('props product', props)
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [currentpage,setCurrentpage] = useState(1);
  const [eventList, setEventList] = useState([]);
  let {event, users, currentUser,dataSources} = props;
  //let sportType = Object.values(dataSources.type_of_sports.options).filter(sports => sports.value == data.sport)

  React.useEffect(() => {
    requestParams = props.match.params.id

    getEventList();
  }, []);

  let getEventList = () => { 
    ProductCommerceService.getById(requestParams).then((res)=>{
     if(res.status == 200){
       data_source = res.data.data_source_list;
       console.log('productgetbyId',res);
       setEventList(res.data.product);
      }
    })
  }
  console.log('productgetbyId',eventList);

  if(eventList.length != 0){
    return (
      <div>
        <DetailList product={eventList} />
      </div>
    );
  }else{
    return (
      <div>
      </div>
    );
  }
 
  
};

export default Details;


/*

<div className="content-boxed shadow-small ht-350" >
      <div className="content vcard-header">
        <ul className="d-flex">
          <li>
            { data.images ? <img className="rounded-circle bg-highlight" src={data.images[0]} width="50px"/>
            :''}
          </li>
          <li className="m-l-15">
            <Link className="black-light-1" to={`/product/details/${event.id}`}>
              <div className="vcard-title">{data.botanical_name}</div>
              <span className="lred">since {event.updated_at}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="content ht-227">
        <div className="vcard-field">
          {data.notes}
        </div> 
       
      </div> 
        <ul className="vard-footer color-highlight">
          <li className="pull-left m-l-15"><props.details ja={event} /></li>
          <li className="pull-left m-l-15">{event.record_id}</li>
          <li className="pull-right m-r-15"><props.menu ja={event}/></li>
        </ul>
    </div>*/