import React, { Fragment, useState, useContext} from 'react';
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../components/PreLoadSpinner";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import { Route, Switch } from 'react-router-dom';
import SideMenu from '../components/SideMenu/SideMenu'
import Header from '../components/Header/Header'
import NotificationList from '../components/Notifications/NotificationList'
import ProductsList from '../components/MyProducts/List/ProductsList'
import OrdersList from '../components/MyOrders/List/OrdersList'
import SampleList from '../components/MySamples/List/SampleList'
import RequestInfoList from '../components/MyRFI/List/RequestInfoList'
import Dashboard from '../components/Dashboard/Dashboard'
import ProductRouter	 from '../components/Product/ProductRouter'
import useModal from "../hooks/useModal";
import TradeReport from "../components/Trade/TradeReport"

const Home = (props) => {
  const { state:{screen, user:currentUser, isAuthenticated:isAuthenticated}, dispatch } = useContext(AuthContext);
  let [openSideNav, setOpenSideNav] = useState(false);
  const [searchString, setSearchString] = useState("");
  const { isOpen:showChatModal, toggleModal:toggleChatModal } = useModal();

  return (
    <Fragment>
      {isAuthenticated && <Header openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} setSearchString={setSearchString} {...props}/>}
      {isAuthenticated && <SideMenu openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} toggleChatModal={toggleChatModal}/>}
      <Switch>
      <PrivateRoute  path="/dashboard" component={Dashboard} exact/>
      <PrivateRoute  path="/myProducts" component={ProductsList} exact/>
      <PrivateRoute  path="/myOrders" component={OrdersList} exact/>
      <PrivateRoute  path="/mySamples" component={SampleList} exact/>
      <PrivateRoute  path="/myRFI" component={RequestInfoList} exact/>
      <PrivateRoute  path="/notifications" component={NotificationList} exact/>
      <Route path="/trade/report" component={TradeReport} exact />
      </Switch>
      <Switch>
      <ProductRouter	 searchString={searchString}/>
      </Switch>
      <ReactTooltip place="left" />
    </Fragment>
  )
}

export default Home;