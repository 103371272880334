import React, {useState, Fragment} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Product from './Product';
import ProductCommerceService from "../ProductCommerceService";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from '../../../constants/FormTemplateList';
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu";
import GalleryModal from "../../Modals/GalleryModal"
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import NotesModal from "../../Modals/NotesModal";
import MenuModal from "../../Modals/Menu";
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle';
import SideMenuBar from "../../Common/SideMenuBar";


let requestParams = {}
let selectedProduct = {};
let data_source = {};
let users = {};

export const ProductList = (props) => {
  useStyle('card_table');
  const { state:authState, dispatch } = React.useContext(AuthContext);
  console.log('authsatet event list',authState)
  const [productList, setProductList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const { isOpen:isGalleryOpen, toggleModal:toggleGalleryModal } = useModal();

  const deleteJourney = (e) => {
    e.preventDefault();
    ProductCommerceService.delete(selectedProduct).then((res) => {
      if (res.status == 204) {
        setProductList(productList.filter(i => i.id !== selectedProduct.id))
      }
    })
    toggleDeleteModal(e);
  }

  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  React.useEffect(() => {
    requestParams = {
      'data_source_params':{"data_source_id":[3306],"response_type":"object"},
      'is_public':true,
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
    }

    getEventList();
  }, [props.searchString, authState.user,currentpage]);

  let getEventList = () => { 
    ProductCommerceService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       data_source = res.data.data_source_list;
       setProductList(res.data.products);
      }
    })
  }

 const addDelete = (e, pr) => {
   console.log('add delete',e,pr)
    selectedProduct = pr;
    toggleDeleteModal(e);
  }

  const requestInfo = (item) =>{
    return(
      <Fragment>
        <Link className="white" to={`/product/requestInfo/product_inquery/${item.id}/312/${item.supplier_id}`}>
          Request Information
        </Link>
      </Fragment>
    )
  }

  const addToCart = (item) =>{
    return(
      <Fragment>
        
      </Fragment>
    )
  }

  const requestSample = (item) => {
    return(
    <Fragment>
     
    </Fragment>
  )}

  const addMenu = (e, pr) => {
    selectedProduct = pr;
    toggleMenuModal(e);
  }

  const productGallery = (e, product) => {
    selectedProduct = product;
    toggleGalleryModal(e);
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link className="" to={`/product/requestInfo/product_inquery/${item.id}/312/${item.supplier_id}`}>
          <i className="fa fa-info"></i>
            <span className="font-13">Request Information</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link className="" to={`/product/requestSample/product_sample_request/${item.id}/332/${item.supplier_id}`}>
          <i className="fa fa-notes-medical"></i>
            <span className="font-13">Request Sample</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link className="" to={`/product/orderForm/${item.id}/${item.supplier_id}`}>
          <i className="fa fa-cart-plus"></i>
            <span className="font-13">Add to Cart</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const Menu = ({ pr, fn }) => (
    <i  onClick={e=>{addMenu(e, pr)}} className="fa fa-ellipsis-h" />     
  )
  
  const productListCard =  productList.map((product, i) => {
    console.log('selected journey',selectedProduct,product)
    return(
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Product menu={Menu} event={product} productGallery={productGallery} addToCart={addToCart} requestSample={requestSample} requestInfo={requestInfo} currentUser={authState.user} users={users}
       toggleModal={toggleDeleteModal} dataSources={data_source}/>
      {selectedProduct.id == product.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedProduct} addDelete={addDelete} type="journey" MenuItems={menuItems} menuHeight="275px"/>
      }
    </div>
  )})
  
  return (
    <React.Fragment>
      <div className="content top-10">
        <h4 className="float-left font-500 m-t-5">Product List</h4>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        {productListCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      {isGalleryOpen && <GalleryModal isOpen={isGalleryOpen} toggleModal={toggleGalleryModal} item={selectedProduct} type="product"/>}
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      <ReactTooltip place="left" />
      <SideMenuBar helpId="2"/>
    </React.Fragment>
  )

};

export default ProductList;
