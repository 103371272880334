import React, {useState, useRef} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';

const SEARCH_WAIT_INTERVAL = 1000;
let searchKeyword = null;
let timer = null;

const InputAutoComplete = ({field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard}) => {
  let pagenate = formFn[field.client_id+'_pagenate'] || {};
  let path = formFn[field.client_id+'_path']|| 'label';
  let op_kv = field.options_key_value;

  searchKeyword = formData[field.client_id];
  const [list, setList] = useState((op_kv && op_kv.options) || []);
  const [showError, setShowError] = useState(false);
  const inputEl = useRef(null);

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    required: field.required
  }

  FormHelper.setDisabled(field, inputAttributes);
  
  const handleChange=(event)=>{
    clearTimeout(timer);
    searchKeyword = event.target.value;
    pagenate.page = 1; 
    timer = setTimeout(onSearch, SEARCH_WAIT_INTERVAL);
  }

  const onSearch = () => {
    if(searchKeyword && searchKeyword != ''){
      formFn['get_'+field.client_id](searchKeyword, onSuccessCallback, onFailureCallback);
      //showResultSet = true; 
      //onChange(search);
    }else{
      setList([]);  
      formData[field.client_id] = null;
      setError(true);
    }
  }

  const onItemSelect = (item) => {
   /* item = FormHelper.isNumeric(item.value);
    formData[field.client_id] = item.value;*/
    formFn['set_'+field.client_id](item);
    inputEl.current.value = formData[field.client_id];
    setList([]);
    setError(true);
  }

  /*execute a function when someone clicks in the document:*/
  /*document.addEventListener("click", function (e) {
    setList([]);
  })*/

  const next = (e) => {
    e.preventDefault();
    if((pagenate.page+1) <= pagenate.pageSize){
      pagenate.page += 1; 
      onSearch();
    }
  }

  const previous = (e) => {
    e.preventDefault();
    if((pagenate.page - 1) > 0){ 
      pagenate.page -= 1; 
      onSearch();
    }
  }

  const onSuccessCallback = (data) =>{
    setList(data);
  }

  const onFailureCallback = (error) =>{
    setList(error);
  }

  const setError = (reset_state) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      if(reset_state){
        setShowError(!showError);
        formFn.refreshFormSubmit();  
      }
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  const highlight = (text, phrase) => {
    if (phrase) text = text.toString();text = text.replace(new RegExp('('+phrase+')', 'gi'),'<strong>$1<strong>')
    return <span dangerouslySetInnerHTML={{ __html: text }} />
  }

  const searchResult = list.map((r, k) =>  
    <div key={k} onClick={e => onItemSelect(r)}>{highlight(r[path])}</div>
  )

  return (
  <div {...parentAttributes}>
    <label {...labelAttributes}>{field.label}</label>
  	<div className="autocomplete">
      <input {...inputAttributes} ref={inputEl} defaultValue={searchKeyword} className="form-control" onChange={e => handleChange(e)} 
      data-tip data-for={`tooltip_${field.client_id}`} />
      <div id="autocomplete-list" className="autocomplete-items">
        { (pagenate.pageSize>1 && list.length>0) &&
        <ul className="pager">
          <li onClick={e => previous(e)}><a href="/">Previous</a></li>
          <li onClick={e => next(e)}><a href="/">Next</a></li>
          <small>{pagenate.page} of {pagenate.pageSize}</small>
        </ul> }
        {searchResult}
      </div> 
      <span className="fts-13">{field.description}</span>
      <Error/>
    </div>
    <CueTooltip id={field.client_id} description={field.tooltip}/>
  </div>
  )
}

export default InputAutoComplete;