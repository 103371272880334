import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';

const InputSelect = ({field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard}) => {
  
  const [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'required':field.required
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let validation = {
    required: field.required
  }
  
  let op_kv = field.options_key_value;
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  const handleChange = (event) =>{
  	const { target } = event;
    const { name } = target;
    if(target.value=='null'){
      formData[name]=null;
    }else{
      formData[name] = FormHelper.isNumeric(target.value);
    }
    setError(false)
    FormHelper.setFormTitle(field, formData, target.value, op_kv)
    forceUpdate();
  }

  const setError = (reset_state) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      if(reset_state){
        setShowError(!showError);
        formFn.refreshFormSubmit();  
      }
    }
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div {...parentAttributes}>
  	  <label {...labelAttributes}>{field.label}</label>
 	    <select {...inputAttributes} onChange={e => handleChange(e)} 
      onBlur={e => {setError(true)}} defaultValue={formData[field.client_id]} className="form-control"
      data-tip data-for={`tooltip_${field.client_id}`}>
      <option value="null"></option>
      {(op_kv && op_kv.options) 
        ? <OptionsObjList list={op_kv.options}/> 
        : <OptionsStringList list={field.options}/>
      }  
      </select> 	 
  	  <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
 	  </div> 
  )
}

const OptionsObjList = ({list}) => list.map((o, k) =>
  <option key={k} value={o.value}>{o.label}</option>
);

const OptionsStringList = ({list}) => list.map((o,k) =>
  <option key={k} value={o}>{o}</option>
)

export default InputSelect;