import React, {Fragment, useState, useEffect} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import importScript from "../../hooks/importScript";

const LineChart = (props) => {
  let [options, setOptions] = useState(false);

  useEffect(() => {
    importScript('/scripts/apexcharts/apexcharts.min.js', ()=>renderChart())
  }, [])

  let renderChart = () =>{
    try{
      let chart = new window.ApexCharts(document.querySelector('#'+props.id), options);
      chart.render();
    }catch(e){
      console.log(e)
      setTimeout(function() {renderChart(); }, 0);
    }
  }

  useEffect(() => {
    options = {
      series: props.series,
      chart: {
        type: props.type,
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: props.dataLabels || false
      },
      stroke: props.stroke,
      markers: {
        size: 0,
      },
      title: props.title,
      yaxis: props.yaxis,
      xaxis: props.xaxis,
      tooltip: {
        
      }
    };
  }, [])

  return(
    <div id={props.id}></div>
  )

}

export default LineChart;