import React, { Fragment, useState,useEffect } from 'react';
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const CookieNotice = (props) => {
  useStyle('cookienotice');
  const [visible, setVisible] = useState(false);
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);

  const toggleAgree =() =>{
    localStorage.is_cookie_accepted = true
    setVisible(!visible)
  }

  if(!visible){
    return (
      <div className="cookie-banner" role="alert">
        <p><strong>NOTICE:</strong>This website and related services use cookies to manage, monitor, and track usage to
        improve services. To learn more, please refer to the <a className="white" target="_blank" href=""><u>cueTree Privacy Policy.</u></a>  By closing this notice or continuing to use this website or related services, you consent to our use of cookies.
        </p>
        <button type="button" onClick={e=>toggleAgree()} className="btn acceptcookies pull-right" aria-label="Close">
          ACCEPT
        </button>
      </div>
    )
  }else{
    return null;
  }

}

export default CookieNotice;