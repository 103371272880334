import env from '../env';
import axios from 'axios';

class AuthenticationService {
  
  signin(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}customer/sign_in`, user).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e.response);
      })
    })
  }

  signup(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}customer/sign_up`, data).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e.response);
      })
    })
  }

  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}users/forgot_password`, user).then((res) => {
        // handle success
        console.log('forgot success',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('forgot eroor',e)
        reject(e);
      })
    })
  }

  resetPassword(userData,userId,token) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}users/`+userId+`/password_reset/`+token+``, userData).then((res) => {
        // handle success
        console.log('reset success',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('reset eroor',e)
        reject(e);
      })
    })
  }

  get(){
    return new Promise((resolve, reject) => {
      var u = env.commerce_backend+'customer'
      axios.get(u).then(function(response){
       resolve( response.data.user );
      }).catch(function(response){
       reject(response)
      });
      
    })
  }

  signout(){
    return new Promise((resolve, reject) => {
      var u = env.commerce_backend+'sign_out'
      axios.delete(u).then(function(response){
        resolve( response.data.user );
      }).catch(function(response){
        reject(response)
      });
    })
  }


  updatePassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}users/change_password`, user).then((res) => {
        resolve(res);
      }).catch((e) => {
        resolve(e);
      })
    })
  }

  updateUserName(user){
    return new Promise((resolve, reject) => {
      axios.put(`${env.commerce_backend}user`, user).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

  updateProfileImage(file){
    return new Promise((resolve, reject) => {
      let postImageUploadurl = env.commerce_backend+'user_profile_image.json';
      let fd = new FormData();
      fd.append('file', file);
      //fd.append('crop', JSON.stringify(file.crop));
      axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}})
      .then((res)=>{
        try{
          res.data.user.profile_image_url = res.data.user.profile_image_url.split('?')[0] 
          resolve(res);
        }catch(e){
          reject(res);
        }
      }).catch((data)=>{
        console.log(data);
      })
    }) 
  }

}

export default new AuthenticationService();