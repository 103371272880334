import React, {useState, useEffect} from 'react'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import RichText from '../../../components/Common/RichText' 

const InputRichText = ({field, formData, readOnly, errors, formFn, isFormWizard, formId}) => {
  const [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const onContentChange = (event) =>{
    formData[field.client_id] = event.target.innerHTML
    setError(event)
  }

  const setError = (event) => {
    if(field.required){
      errors[field.client_id].invalid = formData[field.client_id]?false:true;
      errors[field.client_id].touched = true;
      if(errors[field.client_id].invalid){
        errors.invalid[field.client_id] = true;
      }else{
        delete errors.invalid[field.client_id]
      }
      setShowError(!showError)
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setMinMaxValidation(field, inputAttributes);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  return (
    <div className="form-group col-xs-12 col-sm-6" data-tip data-for={`tooltip_${field.client_id}`}>
      <label {...labelAttributes}>{field.label}</label>
      <RichText onContentChange={onContentChange} content={formData[field.client_id]} dataType={formFn.form_type} dataId={formId}/>
      <span className="fts-13">{field.description}</span>  
    </div> 
  )
}

export default InputRichText;