import React, {useState} from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import moment from '../../../../node_modules/moment/min/moment.min.js'
import "react-datepicker/dist/react-datepicker.css";

// import Calendar Component 

//import DatePicker from 'react-date-picker'
import DatePicker from "react-datepicker";

const InputDateTimePicker = ({field, formData, readOnly, errors, formFn, isFormWizard}) => {
  var dateFormat = "YYYY-MM-DD";
  
  let d = new Date();
  if(field.component=='timepicker')dateFormat = "YYYY-MM-DD HH:mm:ss";

  if(formData[field.client_id+'_formatted_date']){
    d = moment(formData[field.client_id+'_formatted_date']).toDate();
  }
  
  const [value, setDate] = useState(d);
  const [showError, setShowError] = useState(false);

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  const onDateChanged = (value) =>{
    console.log(value)
  	formData[field.client_id+'_formatted_date'] = moment(value).format(dateFormat);
    formData[field.client_id] = moment(value).format(field.dateFormat);
    setDate(value);
    setError();
  }

  FormHelper.setDisabled(field, inputAttributes);
  FormHelper.setDatePicker(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = value ==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
  	  <label {...labelAttributes}>{field.label}</label>
      <DatePicker dateFormat={dateFormat} selected={value} onChange={date => onDateChanged(date)} peekNextMonth
      showMonthDropdown showYearDropdown dropdownMode="select" {...inputAttributes} inline/>
  	  <div className="fts-13">{field.description}</div>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
	  </div> 
  )
 
}

export default InputDateTimePicker;

/*<DatePicker onChange={onDateChanged} value={value} {...inputAttributes}/>*/