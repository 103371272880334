import React, { useState } from 'react';
import CueTooltip from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';

const SEARCH_WAIT_INTERVAL = 1000;
let searchKeyword = null;
let timer = null;

const InputMultiSelectSearch = ({field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard}) => {
  let pagenate = formFn[field.client_id+'_pagenate'] || {};
  let resultPath = formFn[field.client_id+'_path']|| 'label';
  let listKey = field.client_id+'_array';
  formData[listKey] = formData[listKey] || [];

  let [modelarray, setModelarray] = useState(formData[listKey]);
  let [list, setList] = useState([]);

  let objList = {};

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    'required': field.required
  }
      
  const handleChange=(event)=>{
    clearTimeout(timer);
    searchKeyword = event.target.value;
    pagenate.page = 1; 
    timer = setTimeout(onSearch, SEARCH_WAIT_INTERVAL);
  }

  const onSearch = () => {
    if(searchKeyword && searchKeyword != ''){
      formFn['get_'+field.client_id](searchKeyword, onSuccessCallback, onFailureCallback);
      //showResultSet = true; 
      //onChange(search);
    }else{
      setList([]);  
      formData[field.client_id] = null;
      setError(true);
    }
  }

  const onSuccessCallback = (data) =>{
    setList(data);
  }

  const onFailureCallback = (error) =>{
    setList(error);
  }

  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
    let selectedItems = formFn['toggle_'+field.client_id](item);
    setModelarray([...selectedItems]);
    setError();
  }

  const exists = (item) => {
    return formFn['exist_'+field.client_id](item);
  }

  const bindWindowClickEvent = () =>{
    let el = document.getElementById(field.client_id+'_result_set');
    el.classList.remove("hidden");
    window.addEventListener('click',eventFunction)
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field)
      }else{
        let el = document.getElementById(field.client_id+'_result_set');
        el.classList.add("hidden");
        window.removeEventListener("click",eventFunction);
        forceUpdate();
      }
    }catch(e){
    
    }
  }

  FormHelper.setDisabled(field, inputAttributes);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
    <div {...parentAttributes}>
      <label {...labelAttributes}>{field.label}</label>
      <div className={`fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} id={field.client_id+ '_container'}>
      {list && <OptionsObjList list={list} toggle={toggle} handleChange={handleChange}
        exists={exists} modelarray={modelarray} field={field} resultPath={resultPath}
        bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes}/> 
      }   
      </div>  
      <span className="fts-13">{field.description}</span>
      <Error/>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const OptionsObjList = ({list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes, handleChange, resultPath}) => {

  const selectableItems = list.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
     onClick={e => toggle(o)} title={o.description}>
      {o[resultPath]}
    </span>
  )

  const selectedItems = modelarray.map((i, k) =>  
    <div key={k} className="fstChoiceItem">
      {i[resultPath]}
      <span className="fstChoiceRemove" type="button" onClick={e => toggle(i, e)}>×</span>
    </div>
  )

  return(<React.Fragment>
    <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
      {selectedItems}
      <input {...inputAttributes} onChange={e=>handleChange(e)} className="fstQueryInput" placeholder="Search here.." 
      data-tip data-for={`tooltip_${field.client_id}`}/>
    </div>
    <div className="fstResults hidden" id={field.client_id+ "_result_set"}>
      {selectableItems}
    </div>
  </React.Fragment>)
}

export default InputMultiSelectSearch;
