import React, {useState, Fragment, useEffect, useContext} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import RequestInfo from './RequestInfo';
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import NotesModal from "../../Modals/NotesModal";
import MenuModal from "../../Modals/Menu";
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle';
import SideMenuBar from "../../Common/SideMenuBar";

let requestParams = {}
let selectedOrder = {};
let data_source = {};
let users = {};

const RequestInfoList = (props) => {
  useStyle('card_table');

  let params = props.match.params;
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [orderList, setOrderList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);

  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  useEffect(() => {
    requestParams = {
      'page':currentpage,
      'paginate':1,
      'per_page':4,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'product_id':params.product_id
    }

  }, [props.searchString, currentUser,currentpage]);

  const onDelete = (e, order) => {
    selectedOrder = order;
    toggleDeleteModal(e);
  }

  const showMenu = (e, order) => {
    selectedOrder = order;
    toggleMenuModal(e);
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <a href="/" onClick={(e) => {onDelete(e, item);toggleMenuModal(e);}}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ order, fn }) => (
    <i data-tip="Open Tile Menu" onClick={e=>{showMenu(e, order)}} className="fa fa-ellipsis-h m-r-15" />     
  )

  const orderListCard =  orderList.map((s, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <RequestInfo order={s}  menu={Menu} currentUser={currentUser}
      toggleModal={toggleDeleteModal} dataSources={data_source}
      index={i}/>
      
      {selectedOrder.record_id == s.record_id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedOrder} 
        onDelete={onDelete} type="order" MenuItems={menuItems} menuHeight="275px"/>
      }
    </div>
  ))
  
  return (
    <Fragment>
      <div className="content top-10">
        <h4 className="float-left font-500 m-t-5">Request List</h4>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        {orderListCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
        setCurrentpage={setCurrentpage}  itemsPerPage={requestParams.per_page}/>
      </div>
      <ReactTooltip place="left" />
      <SideMenuBar helpId="2"/>
    </Fragment>
  )

}

export default RequestInfoList;