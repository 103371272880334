import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import CommonService from '../../../services/CommonService';

const Sample = (props) => {
  let {order, users, currentUser, dataSources} = props;
  let data = order.data;
  let ds = order.data_source;

  const Header = () => (
    <div className="white card-header p-5 bg-light-green">
      <div className="font-16">
        {order.user_name}
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left">
        <span className="m-l-15 lgrey">
          {order.form_id} - {order.record_id}
        </span>
      </div>
      <div className="pull-right">
        <props.menu order={order} />
      </div>
    </div>
  )

  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName}>{ds[name] && ds[name][label]}</span>
  )

  const OrderCard = () => (
    <div className="card-container bg-white shadow-small">
      <Header/>
      <div className="scroll-auto ht-227">
        <table className="table card-table">
          <tbody>
            <tr>
              <td>
                ID - {order.record_id}
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  Ordered on: {CommonService.formatUpdateDate(order.updated_at)}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                  Shipment: <SpanField name="shipment" label="label"/>
              </td>
            </tr>
            <tr>
              <td>
                  Quantity: <SpanField name="quantity_measure" label="label"/>
              </td>
            </tr>
            <tr>
              <td>
                  Packing: <SpanField name="packaging" label="label"/>
              </td>
            </tr>
            <tr>
              <td>
                  Destination: <SpanField name="port_destination" label="label"/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer/>
    </div>
  )

  return (
    <Fragment>
      <OrderCard/>
      <ReactTooltip place="left" />
    </Fragment>
  )
  
}

export default Sample;