import React, {useState} from "react";
import { Route, Switch, withRouter} from 'react-router-dom';
import Home from "./components/Home";
import TimeSeriesChart from "./components/TimeSeriesChart"
import Login from "./components/Authentication/Login";
import Signup from "./components/Authentication/Signup"
import ForgetPassword from "./components/Authentication/ForgetPassword"
import ResetPassword from './components/Authentication/ResetPassword'
import PublicRoute from './routes/PublicRoute';
import AxiosConfig from './AxiosConfig';
import { AuthContext, initialState } from "./contexts/Authentication/AuthStateProvider";
import Reducer from './contexts/Authentication/AuthReducer';
import AuthenticationService from "./services/AuthService";
import PreLoadSpinner from "./components/PreLoadSpinner";

function App() {
  const [state, dispatch] = React.useReducer(Reducer, initialState);
  const [isUserSessionLoaded, setIsUserSessionLoaded] = useState(false);   

  const getCurrentUserSession = () =>{
    state.getUserSession = true;
    AuthenticationService.get().then((user)=>{
      if(user.id){
        state.user = user
        state.isAuthenticated = true;
      }
      setIsUserSessionLoaded(true);
    }, (err)=>{
      setIsUserSessionLoaded(true);
    }) 
  }

  if(!state.getUserSession)getCurrentUserSession();

  if(!isUserSessionLoaded){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <AuthContext.Provider value={{state, dispatch}}>
      <Switch>
        <PublicRoute path="/" component={Login} exact />
        <PublicRoute path="/signin" component={Login} exact/>
        <PublicRoute path="/signup" component={Signup} exact/>
        <PublicRoute path="/forget_password" component={ForgetPassword} exact/>
        <PublicRoute path="/password_reset/:userId/:token"  component={ResetPassword} exact/>
        <Route path = "/tchart" component={TimeSeriesChart}/>
        <Route path = "/" component={Home} /> 
        <Route component={Page404} />
      </Switch>
    </AuthContext.Provider>
  )
}

const Page404 = ({ location }) => (
  <div>
    <h2>No match found for <code>{location.pathname}</code></h2>
  </div>
)

export default withRouter(App);