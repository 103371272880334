import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import useStyle from '../../hooks/useStyle'

let isPreventStateChangeEnabled = false;

const ExitForm = ({ success, onSubmit}) => {
    useStyle('add_role_dialog');

    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    
    /*React.useEffect(() => {
        return () => {
            history.goForward();
        }
    }, [history.location.pathname]);*/

    try{
        if(!isPreventStateChangeEnabled){
            document.getElementById("back-btn").addEventListener("click", function(event){
                event.stopPropagation();
                setIsOpen(true);
            })  
            isPreventStateChangeEnabled = true;
        }
    }catch(e){
        isPreventStateChangeEnabled = false;
    }

    const toggleModal = (e) =>{
        e.preventDefault();
        history.goBack();
        setIsOpen(!isOpen);
    }

    if(!isOpen){
        console.log(history)
        return null
    }else{
      return(
        <Fragment>
            <div className="ml-modal">
                <div className="clearfix ml-modal-content ml-card-4 ml-animate-zoom bg-grey p-15" style={{width:'550px'}}>
                  <div className="font-18">Attention</div>
                  <div className="font-15 m-t-10">Are you sure you want to exit this form without saving your changes?</div>
                  <div onClick={e=>setIsOpen(false)} className="pull-right grey-btn m-t-10 clearfix">RETURN TO FORM</div> 
                  <div onClick={e=>toggleModal(e)} className="pull-right grey-btn m-t-10">IGNORE CHANGES</div> 
                </div>
           </div>
        </Fragment>
      )
    }
}

export default ExitForm;