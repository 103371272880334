import env from '../../env';
import axios from 'axios';

class ProductCommerceService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.commerce_backend}commerce/public/products`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getById(req){
    console.log('req healthId',req)
    return new Promise((resolve, reject) => {
      axios.get(env.commerce_backend+'commerce/public/products/'+req, { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(journey_category) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}commerce/product_inquries`, journey_category).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
  createOrders(journey_category) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.commerce_backend}commerce/product_orders`, journey_category).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new ProductCommerceService();